import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import './PageStyles.css';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

// Función para formatear números a 1 decimal
function formatAttendance(value) {
  if (value === null || value === undefined) return '-';
  const num = Number(value);
  return Number.isNaN(num) ? '-' : num.toFixed(1);
}

// Mapeo de número de mes a nombre
const monthNames = {
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre"
};

const juniorCourses = [
  "10-4-A", "10-4-B", "10-5-A", "10-5-B"
  // ... otros cursos junior si los tienes
];
const seniorCourses = [
  "110-5-A", "110-5-B", "110-6-A", "110-6-B"
  // ... otros cursos senior si los tienes
];

export default function Dashboard() {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [sede, setSede] = useState('All'); // "All", "Junior", "Senior"
  const [comparisonMonth, setComparisonMonth] = useState(3);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Estados para datos del dashboard y comparación
  const [cursos, setCursos] = useState([]);
  const [top50, setTop50] = useState([]);
  const [bottom50, setBottom50] = useState([]);
  const [comparisonData, setComparisonData] = useState([]); // Datos para el gráfico de barras
  const [comparisonRawData, setComparisonRawData] = useState({}); // Datos para el gráfico multi-año

  // Estado para alumnos (usado para calcular ausentismo anual)
  const [alumnos, setAlumnos] = useState([]);

  // Filtros de búsqueda
  const [courseFilter, setCourseFilter] = useState('');
  const [rankingFilter, setRankingFilter] = useState('');

  // Configuraciones de ordenamiento
  const [sortConfigCourses, setSortConfigCourses] = useState({ key: null, direction: 'asc' });
  const [sortConfigTop, setSortConfigTop] = useState({ key: null, direction: 'asc' });
  const [sortConfigBottom, setSortConfigBottom] = useState({ key: null, direction: 'asc' });

  // Definición única de columnas para la tabla de cursos
  const tableColumns = ['mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic', 'total'];

  // Referencias para capturar imágenes de gráficos
  const barChartRef = useRef(null);
  const lineChartRef = useRef(null);
  const doughnutChartRef = useRef(null);

  // Función para obtener datos del endpoint /dashboard/data
  async function fetchDashboardData() {
    try {
      setLoading(true);
      setError('');
      const response = await axios.get('/dashboard/data', {
        params: { year, sede, comparisonMonth },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      const { cursos, top50, bottom50, comparisonAllYears } = response.data;
      setCursos(cursos || []);
      setTop50(top50 || []);
      setBottom50(bottom50 || []);
      setComparisonData(comparisonAllYears || []);
    } catch (err) {
      setError(err.response?.data?.message || 'Error al obtener datos del dashboard');
      setCursos([]);
      setTop50([]);
      setBottom50([]);
      setComparisonData([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDashboardData();
  }, [year, sede, comparisonMonth]);

  // UseEffect para obtener datos del endpoint /dashboard/comparison
  useEffect(() => {
    async function fetchComparisonData() {
      try {
        const response = await axios.get('/dashboard/comparison', {
          params: { sede },
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setComparisonRawData(response.data);
      } catch (err) {
        console.error('Error fetching comparison data:', err);
      }
    }
    fetchComparisonData();
  }, [sede]);

  // Función para obtener datos de alumnos desde /dashboard/ausentismo
  async function fetchAlumnos() {
    try {
      setLoading(true);
      const response = await axios.get('/dashboard/ausentismo', {
        params: { year, sede },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAlumnos(response.data);
    } catch (err) {
      console.error(err);
      setAlumnos([]);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchAlumnos();
  }, [year, sede]);

  // Ordenamiento de la tabla de cursos
  const sortedCursos = useMemo(() => {
    if (!sortConfigCourses.key) return cursos;
    return [...cursos].sort((a, b) => {
      let aVal = a[sortConfigCourses.key];
      let bVal = b[sortConfigCourses.key];
      if (!isNaN(aVal) && !isNaN(bVal)) {
        return sortConfigCourses.direction === 'asc' ? aVal - bVal : bVal - aVal;
      }
      aVal = aVal.toString().toLowerCase();
      bVal = bVal.toString().toLowerCase();
      return sortConfigCourses.direction === 'asc'
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    });
  }, [cursos, sortConfigCourses]);

  const requestSortCourses = (key) => {
    let direction = 'asc';
    if (sortConfigCourses.key === key && sortConfigCourses.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfigCourses({ key, direction });
  };

  const sortedTop50 = useMemo(() => {
    if (!sortConfigTop.key) return top50;
    return [...top50].sort((a, b) => {
      let aVal = a[sortConfigTop.key];
      let bVal = b[sortConfigTop.key];
      if (!isNaN(aVal) && !isNaN(bVal)) {
        return sortConfigTop.direction === 'asc' ? aVal - bVal : bVal - aVal;
      }
      aVal = aVal.toString().toLowerCase();
      bVal = bVal.toString().toLowerCase();
      return sortConfigTop.direction === 'asc'
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    });
  }, [top50, sortConfigTop]);

  const requestSortTop = (key) => {
    let direction = 'asc';
    if (sortConfigTop.key === key && sortConfigTop.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfigTop({ key, direction });
  };

  const sortedBottom50 = useMemo(() => {
    if (!sortConfigBottom.key) return bottom50;
    return [...bottom50].sort((a, b) => {
      let aVal = a[sortConfigBottom.key];
      let bVal = b[sortConfigBottom.key];
      if (!isNaN(aVal) && !isNaN(bVal)) {
        return sortConfigBottom.direction === 'asc' ? aVal - bVal : bVal - aVal;
      }
      aVal = aVal.toString().toLowerCase();
      bVal = bVal.toString().toLowerCase();
      return sortConfigBottom.direction === 'asc'
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    });
  }, [bottom50, sortConfigBottom]);

  const requestSortBottom = (key) => {
    let direction = 'asc';
    if (sortConfigBottom.key === key && sortConfigBottom.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfigBottom({ key, direction });
  };

  // Fila de totales: calcular promedios globales para cada columna (para la tabla de cursos)
  const aggregatedData = useMemo(() => {
    const totals = {};
    const counts = {};
    tableColumns.forEach(col => {
      totals[col] = 0;
      counts[col] = 0;
    });
    sortedCursos.forEach(course => {
      tableColumns.forEach(col => {
        const value = Number(course[col]);
        if (!Number.isNaN(value) && value > 0) {
          totals[col] += value;
          counts[col]++;
        }
      });
    });
    const averages = {};
    tableColumns.forEach(col => {
      averages[col] = counts[col] > 0 ? (totals[col] / counts[col]).toFixed(1) : '-';
    });
    return averages;
  }, [sortedCursos, tableColumns]);

  // Gráfico de barras para comparación (datos del endpoint /dashboard/data)
  const barChartData = {
    labels: comparisonData.map(item => item.year),
    datasets: [
      {
        label: `Promedio del mes (${comparisonMonth}) (%)`,
        data: comparisonData.map(item => {
          if (!item.avg) return 0;
          const num = Number(item.avg);
          return Number.isNaN(num) ? 0 : +num.toFixed(1);
        }),
        backgroundColor: 'rgba(75,192,192,0.6)'
      }
    ]
  };

  const sedeLabel = sede === 'All' ? 'todos' : (sede === 'Junior' ? 'Sede Junior' : 'Sede Senior');
  const chartTitle = `Comparativo mes de ${monthNames[comparisonMonth]} de ${sedeLabel}`;

  // Gráfico de línea para la progresión anual (del año seleccionado)
  const progressionData = useMemo(() => {
    const months = ['mar','abr','may','jun','jul','ago','sep','oct','nov','dic'];
    const totals = {};
    const counts = {};
    months.forEach(m => {
      totals[m] = 0;
      counts[m] = 0;
    });
    cursos.forEach(course => {
      months.forEach(m => {
        const value = Number(course[m]);
        if (!Number.isNaN(value) && value > 0) {
          totals[m] += value;
          counts[m]++;
        }
      });
    });
    const averages = months.map(m => (counts[m] > 0 ? (totals[m] / counts[m]).toFixed(1) : 0));
    return {
      labels: months.map(m => m.toUpperCase()),
      datasets: [
        {
          label: `Progresión del año ${year} (%)`,
          data: averages,
          fill: false,
          borderColor: 'rgba(54, 162, 235, 1)',
          tension: 0.1,
          backgroundColor: 'rgba(54, 162, 235, 0.3)'
        }
      ]
    };
  }, [cursos, year]);

  // Gráfico de dona para distribución global
  const doughnutData = useMemo(() => {
    let high = 0, mediumHigh = 0, mediumLow = 0, low = 0;
    cursos.forEach(course => {
      const total = Number(course.total);
      if (!isNaN(total)) {
        if (total >= 90) high++;
        else if (total >= 85) mediumHigh++;
        else if (total >= 75) mediumLow++;
        else low++;
      }
    });
    return {
      labels: ['≥90%', '85-90%', '75-85%', '<75%'],
      datasets: [{
        data: [high, mediumHigh, mediumLow, low],
        backgroundColor: ['#27ae60', '#f39c12', '#f1c40f', '#e74c3c']
      }]
    };
  }, [cursos]);

  // Gráfico de comparación multi-año modificado
  const multiYearProgressionData = useMemo(() => {
    const months = ['MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];
    const years = [2023, 2024, 2025];
    const now = new Date();
    const currentMonthNumber = now.getMonth() + 1;
    // Suponiendo que la data inicia en marzo, se consideran activos los meses hasta el actual
    const activeMonthsCount = currentMonthNumber >= 3 ? currentMonthNumber - 3 + 1 : months.length;

    const datasets = years.map(yr => {
      const rawData = comparisonRawData[yr] || {};
      let borderColor, backgroundColor;
      if (yr === 2023) {
        borderColor = '#FF6384';
        backgroundColor = 'rgba(255,99,132,0.3)';
      } else if (yr === 2024) {
        borderColor = '#36A2EB';
        backgroundColor = 'rgba(54,162,235,0.3)';
      } else if (yr === 2025) {
        borderColor = '#FFCE56';
        backgroundColor = 'rgba(255,206,86,0.3)';
      }
      let data = months.map((_, index) => {
        const monthNumber = index + 3;
        return rawData[monthNumber] ? Number(rawData[monthNumber]) : 0;
      });
      // Para el año actual, se dejan en null los meses futuros
      if (yr === new Date().getFullYear()) {
        data = data.map((value, index) => (index < activeMonthsCount ? value : null));
      }
      return {
        label: yr.toString(),
        data,
        fill: false,
        tension: 0.1,
        borderColor,
        backgroundColor
      };
    });
    return {
      labels: months,
      datasets
    };
  }, [comparisonRawData]);

  // Función para calcular el ausentismo anual a nivel de alumnos
  function computeAnnualAusentismoStudents(data) {
    const allMonths = ['mar','abr','may','jun','jul','ago','sep','oct','nov','dic'];
    const now = new Date();
    const currentMonthNumber = now.getMonth() + 1;
    // Se consideran solo los meses ya transcurridos (suponiendo que la data inicia en marzo)
    const activeMonths = allMonths.filter((m, index) => index + 3 <= currentMonthNumber);

    let denominator = 0, reiterado = 0, cronicoGrave = 0, muyGrave = 0;
    data.forEach(st => {
      let sum = 0, count = 0;
      activeMonths.forEach(m => {
        const val = parseFloat(st[m]);
        if (!Number.isNaN(val)) {
          sum += val;
          count++;
        }
      });
      if (count > 0) {
        denominator++;
        const avg = sum / count;
        if (avg >= 85 && avg < 90) {
          reiterado++;
        } else if (avg >= 50 && avg < 85) {
          cronicoGrave++;
        } else if (avg < 50) {
          muyGrave++;
        }
      }
    });
    let totalPercent = '-';
    if (denominator > 0) {
      totalPercent = (((reiterado + cronicoGrave + muyGrave) / denominator) * 100).toFixed(0) + '%';
    }
    return { reiterado, cronicoGrave, muyGrave, totalPercent };
  }

  const annualAusentismoSummary = useMemo(() => {
    return computeAnnualAusentismoStudents(alumnos);
  }, [alumnos]);

  const annualAusentismoDonutData = {
    labels: ['Crónico Reiterado (85-89.9)', 'Crónico Grave (50-84.9)', 'Muy Grave (<50)'],
    datasets: [{
      data: [
        annualAusentismoSummary.reiterado,
        annualAusentismoSummary.cronicoGrave,
        annualAusentismoSummary.muyGrave
      ],
      backgroundColor: ['#8e44ad', '#2980b9', '#e74c3c'],
      hoverBackgroundColor: ['#9b59b6', '#3498db', '#c0392b']
    }]
  };

  // Opciones para los gráficos
  const barOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value) => value + '%',
        color: '#000'
      },
      legend: { display: false },
      title: {
        display: true,
        text: chartTitle
      }
    }
  };

  const progressionOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        formatter: (value) => value + '%',
        align: 'top',
        color: '#000'
      },
      title: {
        display: true,
        text: `Progresión del año ${year} (%)`
      }
    }
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: '#fff',
        formatter: (value, ctx) => {
          let sum = ctx.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
          let percentage = (value * 100 / sum).toFixed(1) + '%';
          return percentage;
        }
      },
      legend: {
        position: 'top',
        align: 'center'
      },
      title: {
        display: true,
        text: 'Distribución Global de Asistencia (Anual)'
      }
    }
  };

  const multiYearProgressionOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        formatter: (value) => value + '%',
        align: 'top',
        color: '#000'
      },
      title: {
        display: true,
        text: 'Comparación de Progresión entre Años'
      },
      legend: { position: 'bottom' }
    }
  };

  // Filtro para la tabla de cursos
  const filteredCursos = sortedCursos.filter(c => {
    if (!courseFilter) return true;
    return c.course.toLowerCase().includes(courseFilter.toLowerCase());
  });

  // Filtros para ranking de alumnos
  const filteredTop50 = sortedTop50.filter(st => {
    if (!rankingFilter) return true;
    const text = rankingFilter.toLowerCase();
    return st.nombre.toLowerCase().includes(text) || st.rut.toLowerCase().includes(text);
  });
  const filteredBottom50 = sortedBottom50.filter(st => {
    if (!rankingFilter) return true;
    const text = rankingFilter.toLowerCase();
    return st.nombre.toLowerCase().includes(text) || st.rut.toLowerCase().includes(text);
  });

  // Función para exportar el Excel con tres hojas (Cursos, Gráficos y Ranking)
  const exportWorkbook = async () => {
    if (filteredTop50.length > 0) {
      console.log("Top ranking keys:", Object.keys(filteredTop50[0]));
    } else {
      console.log("filteredTop50 está vacío");
    }
    
    const workbook = new ExcelJS.Workbook();
    // ===== Hoja 1: Cursos =====
    const coursesSheet = workbook.addWorksheet('Cursos');
    const headerRow = ['CURSO', ...tableColumns.map(col => col.toUpperCase())];
    const header = coursesSheet.addRow(headerRow);
    header.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
    filteredCursos.forEach((curso) => {
      const rowData = [
        curso.course,
        ...tableColumns.map(col => formatAttendance(curso[col]) + '%')
      ];
      coursesSheet.addRow(rowData);
    });
    // ===== Hoja 2: Gráficos =====
    const chartsSheet = workbook.addWorksheet('Gráficos');
    if (barChartRef.current) {
      const barImageUrl = barChartRef.current.toBase64Image();
      if (barImageUrl) {
        const barImageId = workbook.addImage({
          base64: barImageUrl,
          extension: 'png'
        });
        chartsSheet.addImage(barImageId, {
          tl: { col: 0, row: 0 },
          ext: { width: 500, height: 300 }
        });
      }
    }
    if (lineChartRef.current) {
      const lineImageUrl = lineChartRef.current.toBase64Image();
      if (lineImageUrl) {
        const lineImageId = workbook.addImage({
          base64: lineImageUrl,
          extension: 'png'
        });
        chartsSheet.addImage(lineImageId, {
          tl: { col: 6, row: 0 },
          ext: { width: 500, height: 300 }
        });
      }
    }
    if (doughnutChartRef.current) {
      const doughnutImageUrl = doughnutChartRef.current.toBase64Image();
      if (doughnutImageUrl) {
        const doughnutImageId = workbook.addImage({
          base64: doughnutImageUrl,
          extension: 'png'
        });
        chartsSheet.addImage(doughnutImageId, {
          tl: { col: 0, row: 20 },
          ext: { width: 500, height: 300 }
        });
      }
    }
// ===== Hoja 3: Ranking =====
const rankingSheet = workbook.addWorksheet('Ranking');
rankingSheet.getCell('A1').value = 'Ranking 200 porcentajes más altos';
rankingSheet.getCell('A1').font = { bold: true };
// Ajusta el header según las claves reales:
const topHeader = ['tipoEnsenanza', 'nivel', 'curso', 'Nombre', 'RUT', 'Promedio'];
rankingSheet.addRow(topHeader);
filteredTop50.forEach(st => {
  rankingSheet.addRow([
    st.tipo_ensenanza, // en lugar de st.tipo_ensenanza si la clave es camelCase
    st.nivel,
    st.curso,
    st.nombre,
    st.rut,
    formatAttendance(st.avg_attendance) + '%'
  ]);
});
rankingSheet.addRow([]);
rankingSheet.addRow(['Ranking 200 porcentajes más bajos']);
rankingSheet.getCell(`A${rankingSheet.lastRow.number}`).font = { bold: true };
const bottomHeader = ['tipoEnsenanza', 'nivel', 'curso', 'Nombre', 'RUT', 'Promedio'];
rankingSheet.addRow(bottomHeader);
filteredBottom50.forEach(st => {
  rankingSheet.addRow([
    st.tipo_ensenanza,
    st.nivel,
    st.curso,
    st.nombre,
    st.rut,
    formatAttendance(st.avg_attendance) + '%'
  ]);
});
const buf = await workbook.xlsx.writeBuffer();
saveAs(new Blob([buf]), `Dashboard_${year}.xlsx`);
  };

  // Estilo inline para el contenedor de carga (fondo semitransparente con blur)
  const spinnerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  };

  return (
    <div className="dashboard-container">
      <h1>Dashboard de Asistencia</h1>
      
      {/* Filtros */}
      <div className="filters">
        <div className="filter">
          <label>Año:</label>
          <select value={year} onChange={e => setYear(Number(e.target.value))}>
            <option value={2025}>2025</option>
            <option value={2024}>2024</option>
            <option value={2023}>2023</option>
            <option value={2022}>2022</option>
          </select>
        </div>
        <div className="filter">
          <label>Sede:</label>
          <select value={sede} onChange={e => setSede(e.target.value)}>
            <option value="All">Todas</option>
            <option value="Junior">Sede Junior</option>
            <option value="Senior">Sede Senior</option>
          </select>
        </div>
        <div className="filter">
          <label>Mes de comparación:</label>
          <select value={comparisonMonth} onChange={e => setComparisonMonth(Number(e.target.value))}>
            <option value={3}>Marzo</option>
            <option value={4}>Abril</option>
            <option value={5}>Mayo</option>
            <option value={6}>Junio</option>
            <option value={7}>Julio</option>
            <option value={8}>Agosto</option>
            <option value={9}>Septiembre</option>
            <option value={10}>Octubre</option>
            <option value={11}>Noviembre</option>
            <option value={12}>Diciembre</option>
          </select>
        </div>
      </div>
      
      {/* Spinner con overlay */}
      {loading && (
        <div className="loading-container" style={spinnerStyle}>
          <div className="spinner"></div>
          <p>Cargando datos del dashboard...</p>
        </div>
      )}
      {error && <p className="error">{error}</p>}
      
      {/* Contenedor de gráficos */}
      <div className="charts-row">
        <div className="chart-container">
          <h2>{`Comparativo mes de ${monthNames[comparisonMonth]} de ${sede === 'All' ? 'todos' : (sede === 'Junior' ? 'Sede Junior' : 'Sede Senior')}`}</h2>
          {comparisonData.length > 0 ? (
            <Bar ref={barChartRef} data={barChartData} options={barOptions} />
          ) : (
            <p>No hay datos para mostrar.</p>
          )}
        </div>
        <div className="chart-container">
          <h2>Progresión del año {year} (%)</h2>
          {cursos.length > 0 ? (
            <Line ref={lineChartRef} data={progressionData} options={progressionOptions} />
          ) : (
            <p>No hay datos para mostrar.</p>
          )}
        </div>
        <div className="chart-container">
          <h2>Distribución Global de Asistencia (Anual)</h2>
          {cursos.length > 0 ? (
            <Doughnut 
              ref={doughnutChartRef} 
              data={doughnutData} 
              options={doughnutOptions} 
              style={{height: '300px', width: '300px'}} 
            />
          ) : (
            <p>No hay datos para mostrar.</p>
          )}
        </div>
      </div>
      
      {/* Sección combinada: Comparación de Progresión entre Años y Ausentismo Crónico Anual */}
      <div className="charts-row" style={{ marginTop: '30px' }}>
        <div className="chart-container">
          <h2>Comparación de Progresión entre Años</h2>
          <Line data={multiYearProgressionData} options={multiYearProgressionOptions} />
        </div>
        <div className="chart-container">
          <h2>Ausentismo Crónico Anual - Datos</h2>
          <div className="ausentismo-summary-box" style={{ background: '#fdfdfd', border: '1px solid #ecf0f1', borderRadius: '10px', padding: '15px', minWidth: '250px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <p><strong>Crónico Reiterado (85-89.9): </strong>{annualAusentismoSummary.reiterado}</p>
            <p><strong>Crónico Grave (50-84.9): </strong>{annualAusentismoSummary.cronicoGrave}</p>
            <p><strong>Muy Grave (&lt;50): </strong>{annualAusentismoSummary.muyGrave}</p>
            <p><strong>Total Ausentismo: </strong>{annualAusentismoSummary.totalPercent}</p>
          </div>
        </div>
        <div className="chart-container">
          <h2>Ausentismo Crónico Anual - Gráfico</h2>
          <Doughnut 
            data={annualAusentismoDonutData} 
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'bottom', align: 'center' },
                title: { display: true, text: 'Ausentismo Crónico Anual' }
              }
            }}
            style={{height: '300px', width: '300px'}}
          />
        </div>
      </div>
      
      {/* Tabla de cursos */}
      <div>
        <h2>Asistencia Mensual por Curso (Año {year})</h2>
        <button className="create-button" onClick={exportWorkbook}>Exportar a Excel</button>
        <div className="filters">
          <div className="filter">
            <label>Buscar Curso:</label>
            <input
              type="text"
              value={courseFilter}
              onChange={e => setCourseFilter(e.target.value)}
              placeholder="Ej. 5A, IVB, etc."
            />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th onClick={() => requestSortCourses('course')}>CURSO</th>
              {tableColumns.map(col => (
                <th key={col} onClick={() => requestSortCourses(col)}>{col.toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredCursos.map((row, idx) => (
              <tr key={idx}>
                <td>{row.course}</td>
                {tableColumns.map(col => (
                  <td key={col}>{formatAttendance(row[col])}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td><strong>Promedio Global</strong></td>
              {tableColumns.map(col => (
                <td key={col}>{aggregatedData[col] !== undefined ? aggregatedData[col] + '%' : '-'}</td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
      
{/* Ranking de alumnos */}
<div>
  <h2>Ranking de Alumnos (Año {year})</h2>
  <div className="filters">
    <div className="filter">
      <label>Buscar Alumno (nombre o RUT):</label>
      <input
        type="text"
        value={rankingFilter}
        onChange={e => setRankingFilter(e.target.value)}
        placeholder="Ej. Juan, 11.111.111-1"
      />
    </div>
  </div>
  <div className="flex-container">
    <div>
      <h3>Porcentajes más altos</h3>
      <table className="table">
        <thead>
          <tr>
            <th onClick={() => requestSortTop('tipo_ensenanza')}>tipo_ensenanza</th>
            <th onClick={() => requestSortTop('nivel')}>nivel</th>
            <th onClick={() => requestSortTop('curso')}>curso</th>
            <th onClick={() => requestSortTop('nombre')}>Nombre</th>
            <th onClick={() => requestSortTop('rut')}>RUT</th>
            <th onClick={() => requestSortTop('avg_attendance')}>Promedio</th>
          </tr>
        </thead>
        <tbody>
          {filteredTop50.map(st => (
            <tr key={st.estudiante_id}>
              <td>{st.tipo_ensenanza}</td>
              <td>{st.nivel}</td>
              <td>{st.curso}</td>
              <td>{st.nombre}</td>
              <td>{st.rut}</td>
              <td>{formatAttendance(st.avg_attendance)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div>
      <h3>Porcentajes más bajos</h3>
      <table className="table">
        <thead>
          <tr>
            <th onClick={() => requestSortBottom('tipo_ensenanza')}>tipo_ensenanza</th>
            <th onClick={() => requestSortBottom('nivel')}>nivel</th>
            <th onClick={() => requestSortBottom('curso')}>curso</th>
            <th onClick={() => requestSortBottom('nombre')}>Nombre</th>
            <th onClick={() => requestSortBottom('rut')}>RUT</th>
            <th onClick={() => requestSortBottom('avg_attendance')}>Promedio</th>
          </tr>
        </thead>
        <tbody>
          {filteredBottom50.map(st => (
            <tr key={st.estudiante_id}>
              <td>{st.tipo_ensenanza}</td>
              <td>{st.nivel}</td>
              <td>{st.curso}</td>
              <td>{st.nombre}</td>
              <td>{st.rut}</td>
              <td>{formatAttendance(st.avg_attendance)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  {/* Botón para exportar el ranking a Excel */}
  <div style={{ marginTop: '20px', textAlign: 'center' }}>
    <button className="create-button" onClick={exportWorkbook}>
      Exportar Ranking a Excel
    </button>
  </div>
</div>
      
    </div>
  );
}
