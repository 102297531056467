import React, { useState, useEffect } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './ResumenFluidez.css';

// Registro de componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

// Mapeo de grupos (cada grupo agrupa dos cursos)
const groupMapping = {
  "PK": ["PKA", "PKB"],
  "K": ["KA", "KB"],
  "1": ["1A", "1B"],
  "2": ["2A", "2B"],
  "3": ["3A", "3B"],
  "4": ["4A", "4B"],
  "5": ["5A", "5B"],
  "6": ["6A", "6B"],
  "7": ["7A", "7B"],
  "8": ["8A", "8B"],
  "I": ["IA", "IB"],
  "II": ["IIA", "IIB"],
  "III": ["IIIA", "IIIB"],
  "IV": ["IVA", "IVB"]
};

// Orden deseado para el select de grupos
const groupOrder = ["PK", "K", "1", "2", "3", "4", "5", "6", "7", "8", "I", "II", "III", "IV"];

// Umbrales para Fluidez Lectora
const thresholds = {
  "PK": { remedial: 20, general: 37 },
  "K": { remedial: 41, general: 63 },
  "1": { remedial: 20, general: 37 },
  "2": { remedial: 41, general: 63 },
  "3": { remedial: 62, general: 87 },
  "4": { remedial: 83, general: 110 },
  "5": { remedial: 102, general: 135 },
  "6": { remedial: 123, general: 160 },
  "7": { remedial: 123, general: 160 },
  "8": { remedial: 123, general: 160 },
  "I": { remedial: 123, general: 160 },
  "II": { remedial: 123, general: 160 },
  "III": { remedial: 123, general: 160 },
  "IV": { remedial: 123, general: 160 }
};

// Umbrales para Matemáticas - Adición/Sustracción
const thresholdsMathAdd = {
  "PK": { remedial: 20, general: 27 },
  "K": { remedial: 20, general: 27 },
  "1": { remedial: 20, general: 27 },
  "2": { remedial: 23, general: 31 },
  "3": { remedial: 29, general: 41 },
  "4": { remedial: 32, general: 41 },
  "5": { remedial: 34, general: 43 },
  "6": { remedial: 36, general: 45 },
  "7": { remedial: 37, general: 46 },
  "8": { remedial: 38, general: 47 },
  "I": { remedial: 38, general: 47 },
  "II": { remedial: 38, general: 47 },
  "III": { remedial: 38, general: 47 },
  "IV": { remedial: 38, general: 47 }
};

// Umbrales para Matemáticas - Multiplicación
const thresholdsMathMult = {
  "PK": { remedial: 30, general: 35 },
  "K": { remedial: 30, general: 35 },
  "1": { remedial: 30, general: 35 },
  "2": { remedial: 30, general: 35 },
  "3": { remedial: 30, general: 35 },
  "4": { remedial: 30, general: 35 },
  "5": { remedial: 36, general: 42 },
  "6": { remedial: 40, general: 45 },
  "7": { remedial: 41, general: 46 },
  "8": { remedial: 42, general: 47 },
  "I": { remedial: 42, general: 47 },
  "II": { remedial: 42, general: 47 },
  "III": { remedial: 42, general: 47 },
  "IV": { remedial: 42, general: 47 }
};

// Función auxiliar para obtener el valor de un estudiante para una evaluación específica
const obtenerValorParaEval = (student, evalKey, tipo) => {
  let valor = null;
  if (['1', '2', '3', '4'].includes(evalKey)) {
    if (tipo === 'lectora') {
      const dato = student[`reading_eval${evalKey}`];
      valor = (dato === null || String(dato).trim() === '') ? null : Number(dato);
    } else {
      const dato = student[`math_eval${evalKey}`];
      valor = (dato === null || String(dato).trim() === '') ? null : Number(dato);
    }
  } else if (['1a', '2a', '3a', '4a'].includes(evalKey)) {
    const dato = student[`math_eval${evalKey}`];
    valor = (dato === null || String(dato).trim() === '') ? null : Number(dato);
  }
  return isNaN(valor) ? null : valor;
};

// Componente para el gráfico de evolución (Line)
// Recibe como props los estudiantes, grupo, tipo y un filtro ("promedio" o un subnivel específico)
const EvolucionChart = ({ students, group, tipo, filter }) => {
  // Filtrar estudiantes: si filter es "promedio" se usan todos (ya filtrados por grupo) y, de lo contrario, solo los del subnivel
  const filteredStudents = filter === "promedio"
    ? students
    : students.filter(student => student.nivel === filter);

  // Para el gráfico de evolución usamos evaluaciones 1 a 4
  const evalKeys = ['1', '2', '3', '4'];

  const remedialCounts = [];
  const generalCounts = [];
  const fomentoCounts = [];

  evalKeys.forEach(key => {
    let currentThreshold;
    if (tipo === 'matematica') {
      // Para evolución usamos, por ejemplo, los umbrales de adición/sustracción
      currentThreshold = thresholdsMathAdd[group];
    } else {
      currentThreshold = thresholds[group];
    }
    let remedial = 0, general = 0, fomento = 0;
    filteredStudents.forEach(student => {
      const val = obtenerValorParaEval(student, key, tipo);
      if (val === null || !currentThreshold) return;
      if (val <= currentThreshold.remedial) {
        remedial++;
      } else if (val <= currentThreshold.general) {
        general++;
      } else {
        fomento++;
      }
    });
    remedialCounts.push(remedial);
    generalCounts.push(general);
    fomentoCounts.push(fomento);
  });

  const data = {
    labels: evalKeys.map(k => `Eval ${k}`),
    datasets: [
      {
        label: 'Remedial',
        data: remedialCounts,
        borderColor: '#e74c3c',
        backgroundColor: '#e74c3c',
        fill: false,
      },
      {
        label: 'General',
        data: generalCounts,
        borderColor: '#f1c40f',
        backgroundColor: '#f1c40f',
        fill: false,
      },
      {
        label: 'Fomento',
        data: fomentoCounts,
        borderColor: '#2ecc71',
        backgroundColor: '#2ecc71',
        fill: false,
      },
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: filter === "promedio" ? "Evolución Global" : `Evolución ${filter}` }
    },
    scales: {
      y: { beginAtZero: true }
    }
  };

  return (
    <div className="chart-container">
      <Line data={data} options={options} />
    </div>
  );
};

const ResumenFluidez = () => {
  // Filtros
  const [year, setYear] = useState('');
  const [tipo, setTipo] = useState('lectora'); // "lectora" o "matematica"
  const [group, setGroup] = useState('');
  const [evaluacion, setEvaluacion] = useState(''); // "1", "2", "3", "4", "1a", "2a", etc. o "todas"
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  // Resumen global (cantidades)
  const [resumen, setResumen] = useState({ remedial: 0, general: 0, fomento: 0, total: 0 });

  // Obtención de estudiantes según el año y grupo
  const fetchStudents = async () => {
    if (!year) return;
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `https://asistencia-betterland.onrender.com/fluidez/estudiantes?yearFluidez=${year}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      if (response.ok) {
        let data = await response.json();

        // Filtrar por grupo si se seleccionó uno
        if (group && groupMapping[group]) {
          const cursos = groupMapping[group];
          data = data.filter(student => cursos.includes(student.nivel));
        }

        // Ordenar estudiantes
        data.sort((a, b) => {
          const apComp = a.lastnamepaternal.localeCompare(b.lastnamepaternal);
          if (apComp !== 0) return apComp;
          const amComp = a.lastnamematernal.localeCompare(b.lastnamematernal);
          if (amComp !== 0) return amComp;
          return a.firstname.localeCompare(b.firstname);
        });

        setStudents(data);
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.error("Error al cargar estudiantes:", error);
      setStudents([]);
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener el valor a evaluar de cada estudiante (usada para la tabla y gráfica de dona)
  const obtenerValor = (student) => {
    let valor;
    if (evaluacion === '1' || evaluacion === '2' || evaluacion === '3' || evaluacion === '4') {
      let dato;
      if (tipo === 'lectora') {
        dato = student[`reading_eval${evaluacion}`];
      } else {
        dato = student[`math_eval${evaluacion}`];
      }
      valor = (dato === null || String(dato).trim() === '') ? null : Number(dato);
    } else if (evaluacion === '1a' || evaluacion === '2a' || evaluacion === '3a' || evaluacion === '4a') {
      let dato = student[`math_eval${evaluacion}`];
      valor = (dato === null || String(dato).trim() === '') ? null : Number(dato);
    } else if (evaluacion === 'todas') {
      if (tipo === 'lectora') {
        const rawVals = [
          student.reading_eval1,
          student.reading_eval2,
          student.reading_eval3,
          student.reading_eval4
        ];
        const validVals = rawVals.filter(v => v !== null && String(v).trim() !== '');
        const numVals = validVals.map(v => Number(v));
        valor = numVals.length ? numVals.reduce((a, b) => a + b, 0) / numVals.length : NaN;
      } else {
        const rawVals = [
          student.math_eval1,
          student.math_eval2,
          student.math_eval3,
          student.math_eval4
        ];
        const validVals = rawVals.filter(v => v !== null && String(v).trim() !== '');
        const numVals = validVals.map(v => Number(v));
        valor = numVals.length ? numVals.reduce((a, b) => a + b, 0) / numVals.length : NaN;
      }
    }
    return isNaN(valor) ? null : valor;
  };

  // Calcula el resumen (cantidad de estudiantes por categoría) para una lista dada
  const computeSummaryForStudents = (studentList) => {
    if (!group) return { remedialCount: 0, generalCount: 0, fomentoCount: 0, total: 0 };
    
    let currentThreshold;
    if (tipo === 'matematica') {
      if (evaluacion === 'todas' || !evaluacion.endsWith('a')) {
        currentThreshold = thresholdsMathAdd[group];
      } else {
        currentThreshold = thresholdsMathMult[group];
      }
    } else {
      currentThreshold = thresholds[group];
    }
    if (!currentThreshold) return { remedialCount: 0, generalCount: 0, fomentoCount: 0, total: 0 };
    
    let remedialCount = 0, generalCount = 0, fomentoCount = 0;
    studentList.forEach(student => {
      const valor = obtenerValor(student);
      if (valor === null) return;
      if (valor <= currentThreshold.remedial) {
        remedialCount++;
      } else if (valor <= currentThreshold.general) {
        generalCount++;
      } else {
        fomentoCount++;
      }
    });
    return { remedialCount, generalCount, fomentoCount, total: remedialCount + generalCount + fomentoCount };
  };

  // Calcula y actualiza el resumen global
  const calcularResumenGlobal = () => {
    const { remedialCount, generalCount, fomentoCount, total } = computeSummaryForStudents(students);
    setResumen({ remedial: remedialCount, general: generalCount, fomento: fomentoCount, total });
  };

  useEffect(() => { if (year) fetchStudents(); }, [year, group]);
  useEffect(() => { calcularResumenGlobal(); }, [students, evaluacion, tipo, group]);

  // Prepara los datos para el gráfico de dona (global o por subnivel)
  const prepareChartData = (summary) => {
    const { remedialCount, generalCount, fomentoCount, total } = summary;
    const remedialPerc = total ? parseFloat(((remedialCount / total) * 100).toFixed(1)) : 0;
    const generalPerc  = total ? parseFloat(((generalCount  / total) * 100).toFixed(1)) : 0;
    const fomentoPerc  = total ? parseFloat(((fomentoCount  / total) * 100).toFixed(1)) : 0;
    
    return {
      labels: ['Remedial', 'General', 'Fomento'],
      datasets: [
        {
          data: [remedialPerc, generalPerc, fomentoPerc],
          backgroundColor: ['#e74c3c', '#f1c40f', '#2ecc71'],
          hoverBackgroundColor: ['#c0392b', '#f39c12', '#27ae60']
        }
      ]
    };
  };

  // Datos para el gráfico global de dona
  const globalChartData = prepareChartData({
    remedialCount: resumen.remedial,
    generalCount: resumen.general,
    fomentoCount: resumen.fomento,
    total: resumen.total
  });

  // Subniveles según grupo seleccionado
  const subniveles = group && groupMapping[group] ? groupMapping[group] : [];

  // Determinación de los umbrales actuales según tipo y evaluación
  const currentThreshold = (() => {
    if (!group) return null;
    if (tipo === 'matematica') {
      if (evaluacion === 'todas' || !evaluacion.endsWith('a')) {
        return thresholdsMathAdd[group];
      } else {
        return thresholdsMathMult[group];
      }
    } else {
      return thresholds[group];
    }
  })();

  return (
    <div className="page-content resumen-fluidez">
      <h2 className="page-title">Resumen del Programa de Fluidez</h2>
      
      {/* Filtros */}
      <div className="card filtros-resumen">
        <div className="filtro">
          <label>Año:</label>
          <select value={year} onChange={e => setYear(e.target.value)}>
            <option value="">Seleccione el año</option>
            <option value="2025">2025</option>
          </select>
        </div>
        <div className="filtro">
          <label>Tipo de Fluidez:</label>
          <select value={tipo} onChange={e => setTipo(e.target.value)}>
            <option value="lectora">Fluidez Lectora</option>
            <option value="matematica">Fluidez Matemáticas</option>
          </select>
        </div>
        <div className="filtro">
          <label>Nivel:</label>
          <select value={group} onChange={e => setGroup(e.target.value)}>
            <option value="">Seleccione Grupo</option>
            {groupOrder.map(g => (<option key={g} value={g}>{g}</option>))}
          </select>
        </div>
        <div className="filtro">
          <label>Evaluación:</label>
          <select value={evaluacion} onChange={e => setEvaluacion(e.target.value)}>
            <option value="">Seleccione Evaluación</option>
            {tipo === 'matematica' ? (
              <>
                <option value="1">Evaluación 1 (Adición/Sustracción)</option>
                <option value="2">Evaluación 2 (Adición/Sustracción)</option>
                <option value="3">Evaluación 3 (Adición/Sustracción)</option>
                <option value="4">Evaluación 4 (Adición/Sustracción)</option>
                <option value="1a">Evaluación 1 (Multiplicación)</option>
                <option value="2a">Evaluación 2 (Multiplicación)</option>
                <option value="3a">Evaluación 3 (Multiplicación)</option>
                <option value="4a">Evaluación 4 (Multiplicación)</option>
                <option value="todas">Todas</option>
              </>
            ) : (
              <>
                <option value="1">Evaluación 1</option>
                <option value="2">Evaluación 2</option>
                <option value="3">Evaluación 3</option>
                <option value="4">Evaluación 4</option>
                <option value="todas">Todas</option>
              </>
            )}
          </select>
        </div>
      </div>

      <div className="card">
        {loading ? (
          <p>Cargando datos...</p>
        ) : (
          <>
            <h3>
              Resumen para {tipo === 'lectora' ? 'Fluidez Lectora' : 'Fluidez Matemáticas'} del grupo {group} - {year}
            </h3>
            
            {/* Tabla resumen global */}
            <table className="resumen-table">
              <thead>
                <tr>
                  <th>Categoría</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Remedial</td>
                  <td>{resumen.remedial}</td>
                </tr>
                <tr>
                  <td>General</td>
                  <td>{resumen.general}</td>
                </tr>
                <tr>
                  <td>Fomento</td>
                  <td>{resumen.fomento}</td>
                </tr>
              </tbody>
            </table>
            
            {/* Sección de gráficos globales: se muestran el gráfico de dona y, junto a él, el gráfico de evolución */}
            <div className="chart-row">
              <div className="chart-container">
                <Doughnut data={globalChartData} />
              </div>
              <EvolucionChart 
                students={students} 
                group={group} 
                tipo={tipo} 
                filter="promedio" 
              />
            </div>

            {/* Listado por subnivel con tabla de 3 columnas */}
            {subniveles.length > 0 && subniveles.map((subnivel) => {
              const estudiantesSub = students.filter(student => student.nivel === subnivel);
              const subSummary = computeSummaryForStudents(estudiantesSub);
              const subChartData = prepareChartData(subSummary);

              const remedialStudents = estudiantesSub.filter(student => {
                const valor = obtenerValor(student);
                return valor !== null && valor <= (currentThreshold ? currentThreshold.remedial : 0);
              });
              const generalStudents = estudiantesSub.filter(student => {
                const valor = obtenerValor(student);
                return valor !== null && valor > (currentThreshold ? currentThreshold.remedial : 0) && valor <= (currentThreshold ? currentThreshold.general : 0);
              });
              const fomentoStudents = estudiantesSub.filter(student => {
                const valor = obtenerValor(student);
                return valor !== null && valor > (currentThreshold ? currentThreshold.general : 0);
              });

              return (
                <div key={subnivel} className="subnivel-resumen">
                  <h4>Resumen para el curso {subnivel}</h4>
                  <div className="chart-row">
                    <div className="chart-container">
                      <Doughnut data={subChartData} />
                    </div>
                    <EvolucionChart 
                      students={students} 
                      group={group} 
                      tipo={tipo} 
                      filter={subnivel} 
                    />
                  </div>
                  <div className="categorias-container">
                    <div className="categoria-column">
                      <h6>Remedial ({remedialStudents.length})</h6>
                      {remedialStudents.length > 0 ? (
                        <ul>
                          {remedialStudents.map(student => (
                            <li key={student.rut}>
                              {student.lastnamepaternal} {student.lastnamematernal} {student.firstname}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No hay estudiantes en Remedial</p>
                      )}
                    </div>
                    <div className="categoria-column">
                      <h6>General ({generalStudents.length})</h6>
                      {generalStudents.length > 0 ? (
                        <ul>
                          {generalStudents.map(student => (
                            <li key={student.rut}>
                              {student.lastnamepaternal} {student.lastnamematernal} {student.firstname}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No hay estudiantes en General</p>
                      )}
                    </div>
                    <div className="categoria-column">
                      <h6>Fomento ({fomentoStudents.length})</h6>
                      {fomentoStudents.length > 0 ? (
                        <ul>
                          {fomentoStudents.map(student => (
                            <li key={student.rut}>
                              {student.lastnamepaternal} {student.lastnamematernal} {student.firstname}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No hay estudiantes en Fomento</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default ResumenFluidez;
