import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import './PageStyles.css';

import { Line, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

/**
 * Formatea un valor numérico exactamente como viene de la BD:
 * - Si no es parseable => "-"
 * - Si es entero exacto, se muestra sin decimales.
 * - Si tiene decimales, se preservan hasta 2 decimales.
 */
function formatExactDbValue(valString) {
  if (valString === null || valString === undefined) return '-';
  const val = Number(valString);
  if (Number.isNaN(val)) return '-';
  const original = String(valString).trim();
  const matchDecimals = original.match(/^\d+(\.\d+)?$/);
  if (!matchDecimals) {
    return val.toFixed(2);
  }
  if (original.includes('.')) {
    const parts = original.split('.');
    const decimals = parts[1] || '';
    if (decimals.length === 0) {
      return parts[0];
    } else if (decimals.length === 1) {
      return `${parts[0]}.${decimals}`;
    } else {
      return val.toFixed(2);
    }
  } else {
    return original;
  }
}

/**
 * Retorna el tooltip con los días presentes y ausentes para el mes.
 * Se asume que:
 *   - "percentage" es el porcentaje de asistencia del mes (por ejemplo, 85)
 *   - "present" es la cantidad de días presentes
 *   - "absent" es la cantidad de días ausentes
 * El total de días trabajados se calcula como la suma de present y absent.
 */
const getAttendanceTooltip = (percentage, present, absent) => {
  if (present === undefined || absent === undefined) return '';
  const total = Number(present) + Number(absent);
  return `Presente: ${present} días, Ausente: ${absent} días (Total: ${total})`;
};

export default function Matriculas() {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [tipoEnsenanza, setTipoEnsenanza] = useState('');
  const [nivel, setNivel] = useState('');
  const [curso, setCurso] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const exportRef = useRef();

  // Estado para ranking mensual: mes seleccionado
  const [selectedRankingMonth, setSelectedRankingMonth] = useState('');
  // Estado para Ausentismo Crónica: mes seleccionado
  const [selectedCronicMonth, setSelectedCronicMonth] = useState('');

  // Arreglo completo de meses
  const months = ['ene','feb','mar','abr','may','jun','jul','ago','sep','oct','nov','dic'];
  // Los meses de cálculo (desde marzo)
  const calculationMonths = ['mar','abr','may','jun','jul','ago','sep','oct','nov','dic'];

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const nivelOptionsMap = {
    '10': [4, 5],
    '110': [1, 2, 3, 4, 5, 6, 7, 8],
    '310': [1, 2, 3, 4]
  };

  const getNivelOptions = () => {
    if (!tipoEnsenanza || !nivelOptionsMap[tipoEnsenanza]) return [];
    return nivelOptionsMap[tipoEnsenanza];
  };

  useEffect(() => {
    const allowed = getNivelOptions();
    if (nivel && !allowed.includes(Number(nivel))) {
      setNivel('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoEnsenanza]);

  // Calcula el índice global del último mes con datos (en el arreglo "months")
  const globalLastTaughtIndex = useMemo(() => {
    let maxIndex = -1;
    students.forEach(student => {
      for (let i = 0; i < months.length; i++) {
        const val = parseFloat(student[months[i]]);
        if (!Number.isNaN(val) && i > maxIndex) {
          maxIndex = i;
        }
      }
    });
    return maxIndex;
  }, [students]);

  /**
   * Un alumno se considera activo (no retirado) si su último dato válido 
   * coincide con el último mes global con datos.
   */
  const isActiveStudent = (student, globalIndex) => {
    let studentLastIndex = -1;
    for (let i = 0; i < months.length; i++) {
      const val = parseFloat(student[months[i]]);
      if (!Number.isNaN(val)) {
        studentLastIndex = i;
      }
    }
    if (studentLastIndex === -1) return false;
    // Si el alumno dejó de tener datos antes del último mes global, se asume que se retiró.
    return studentLastIndex === globalIndex;
  };

  /**
   * Calcula el promedio individual del alumno, promediando desde "mar" hasta el último mes enseñado globalmente.
   * Si el alumno no está activo (se retiró) se retorna '-'.
   */
  const getStudentAverage = (student) => {
    const startIndex = months.indexOf('mar');
    if (globalLastTaughtIndex < startIndex) return '-';
    if (!isActiveStudent(student, globalLastTaughtIndex)) return '-';
    let sum = 0, count = 0;
    for (let i = startIndex; i <= globalLastTaughtIndex; i++) {
      const m = months[i];
      const val = parseFloat(student[m]);
      if (!Number.isNaN(val)) {
        sum += val;
        count++;
      }
    }
    if (count === 0) return '-';
    const avg = sum / count;
    return formatExactDbValue(avg) + '%';
  };

  /**
   * Calcula los promedios globales, pero solo tomando en cuenta los alumnos activos
   * y solo los meses que se han cursado (desde "mar" hasta el último mes global).
   */
  const getGlobalAverages = (data) => {
    const active = data.filter(st => isActiveStudent(st, globalLastTaughtIndex));
    if (active.length === 0) return null;
    const averages = {};
    // Para cada mes del año: si el mes es posterior al último enseñado, se muestra '-'
    for (let i = 0; i < months.length; i++) {
      const m = months[i];
      if (i > globalLastTaughtIndex) {
        averages[m] = '-';
      } else {
        let sum = 0, count = 0;
        active.forEach(st => {
          const val = parseFloat(st[m]);
          if (!Number.isNaN(val)) {
            sum += val;
            count++;
          }
        });
        averages[m] = count === 0 ? '-' : formatExactDbValue(sum / count) + '%';
      }
    }
    // Promedio global sobre el periodo de "mar" hasta el último mes enseñado
    const startIndex = months.indexOf('mar');
    let globalSum = 0, globalCount = 0;
    active.forEach(st => {
      for (let i = startIndex; i <= globalLastTaughtIndex; i++) {
        const m = months[i];
        const val = parseFloat(st[m]);
        if (!Number.isNaN(val)) {
          globalSum += val;
          globalCount++;
        }
      }
    });
    averages.total = globalCount === 0 ? '-' : formatExactDbValue(globalSum / globalCount) + '%';
    return averages;
  };

  const computeAusentismoSummary = (data) => {
    const summary = {};
    calculationMonths.forEach(m => {
      let denom = 0, reiterado = 0, cronicoGrave = 0, muyGrave = 0;
      data.forEach(st => {
        const val = parseFloat(st[m]);
        if (!Number.isNaN(val)) {
          denom++;
          if (val >= 85 && val < 90) {
            reiterado++;
          } else if (val >= 50 && val < 85) {
            cronicoGrave++;
          } else if (val < 50) {
            muyGrave++;
          }
        }
      });
      let totalPercent = '-';
      const ausCount = reiterado + cronicoGrave + muyGrave;
      if (denom > 0 && ausCount > 0) {
        totalPercent = ((ausCount / denom) * 100).toFixed(0);
      }
      summary[m] = { reiterado, cronicoGrave, muyGrave, totalPercent };
    });
    return summary;
  };

  function getAnnualRanking(data) {
    const active = data.filter(st => isActiveStudent(st, globalLastTaughtIndex));
    const ranked = active.map(st => ({
      nombre: st.nombre,
      rut: st.rut,
      promedio: parseFloat(getStudentAverage(st))
    })).filter(st => !Number.isNaN(st.promedio));
    const worst = [...ranked].sort((a, b) => a.promedio - b.promedio).slice(0, 35);
    const best = [...ranked].sort((a, b) => b.promedio - a.promedio).slice(0, 35);
    return { worst, best };
  }
  const annualRanking = students.length > 0 ? getAnnualRanking(students) : null;

  const getMonthlyRanking = (month, data) => {
    const filtered = data.filter(st => {
      const val = parseFloat(st[month]);
      return !Number.isNaN(val);
    });
    const sortedAsc = [...filtered].sort((a, b) => parseFloat(a[month]) - parseFloat(b[month]));
    const sortedDesc = [...filtered].sort((a, b) => parseFloat(b[month]) - parseFloat(a[month]));
    const worst = sortedAsc.slice(0, 35).map(st => ({
      nombre: st.nombre,
      rut: st.rut,
      value: formatExactDbValue(st[month])
    }));
    const best = sortedDesc.slice(0, 35).map(st => ({
      nombre: st.nombre,
      rut: st.rut,
      value: formatExactDbValue(st[month])
    }));
    return { worst, best };
  };

  const fetchStudents = async () => {
    if (!year || !tipoEnsenanza || !nivel || !curso) {
      setStudents([]);
      return;
    }
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('/matriculas', {
        params: { year, tipoEnsenanza, nivel, curso },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setStudents(response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Error al obtener datos');
      setStudents([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, tipoEnsenanza, nivel, curso]);

  const globalAverages = students.length > 0 ? getGlobalAverages(students) : null;
  const ausentismoSummary = students.length > 0 ? computeAusentismoSummary(students) : null;

  let chartData = null;
  if (ausentismoSummary) {
    const dataPoints = calculationMonths.map(m => {
      const tp = ausentismoSummary[m].totalPercent;
      return tp === '-' ? null : Number(tp);
    });
    chartData = {
      labels: calculationMonths.map(m => m.toUpperCase()),
      datasets: [
        {
          label: 'Total Ausentismo (%)',
          data: dataPoints,
          borderColor: 'rgba(255,99,132,1)',
          backgroundColor: 'rgba(255,99,132,0.2)',
          fill: true,
          tension: 0.3
        }
      ]
    };
  }

  const getDonutChartData = () => {
    const activeStudents = students.filter(st => isActiveStudent(st, globalLastTaughtIndex));
    let catAbove90 = 0, cat85to90 = 0, cat70to85 = 0, catBelow70 = 0;
    activeStudents.forEach(st => {
      const avgStr = getStudentAverage(st);
      const avg = parseFloat(avgStr);
      if (!isNaN(avg)) {
        if (avg > 90) catAbove90++;
        else if (avg >= 85 && avg <= 90) cat85to90++;
        else if (avg >= 70 && avg < 85) cat70to85++;
        else if (avg < 70) catBelow70++;
      }
    });
    return {
      labels: ['+90', '85-90', '70-85', '-70'],
      datasets: [{
        data: [catAbove90, cat85to90, cat70to85, catBelow70],
        backgroundColor: [
          '#2ecc71',
          '#3498db',
          '#f1c40f',
          '#e74c3c'
        ],
        hoverBackgroundColor: [
          '#27ae60',
          '#2980b9',
          '#f39c12',
          '#c0392b'
        ]
      }]
    };
  };

  const monthlyRanking = selectedRankingMonth
    ? getMonthlyRanking(selectedRankingMonth, students)
    : null;

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedStudents = useMemo(() => {
    let sortableStudents = [...students];
    if (sortConfig.key) {
      sortableStudents.sort((a, b) => {
        let aVal, bVal;
        if (sortConfig.key === 'nombre' || sortConfig.key === 'rut') {
          aVal = a[sortConfig.key].toLowerCase();
          bVal = b[sortConfig.key].toLowerCase();
        } else if (sortConfig.key === 'promedio') {
          aVal = parseFloat(getStudentAverage(a));
          bVal = parseFloat(getStudentAverage(b));
        } else if (months.includes(sortConfig.key)) {
          aVal = parseFloat(a[sortConfig.key]);
          bVal = parseFloat(b[sortConfig.key]);
        }
        if (isNaN(aVal)) aVal = -Infinity;
        if (isNaN(bVal)) bVal = -Infinity;
        if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sortableStudents;
  }, [students, sortConfig]);

  function exportPDF() {
    const input = exportRef.current;
    if (!input) return;
    html2canvas(input, { scale: 2, useCORS: true }).then(canvas => {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const margin = 10;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const usableWidth = pageWidth - margin * 2;
      const factor = usableWidth / canvas.width;
      
      let currentY = 0;
      while (currentY < canvas.height) {
        const sectionHeightPx = Math.min(canvas.height - currentY, (pdf.internal.pageSize.getHeight() - margin * 2) / factor);
        const pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        pageCanvas.height = sectionHeightPx;
        const ctx = pageCanvas.getContext('2d');
        ctx.drawImage(
          canvas,
          0,
          currentY,
          canvas.width,
          sectionHeightPx,
          0,
          0,
          canvas.width,
          sectionHeightPx
        );
        const imgData = pageCanvas.toDataURL('image/png');
        if (currentY !== 0) {
          pdf.addPage();
        }
        const pageImgHeight = sectionHeightPx * factor;
        pdf.addImage(imgData, 'PNG', margin, margin, usableWidth, pageImgHeight);
        currentY += sectionHeightPx;
      }
      pdf.save(`matriculas_${year}_${tipoEnsenanza}_${nivel}_${curso}.pdf`);
    });
  }

  async function exportExcel() {
    const workbook = new ExcelJS.Workbook();

    // ===== Hoja 1: Matrículas =====
    const matriculasSheet = workbook.addWorksheet('Matrículas');
    const headerMat = ['Nombre', 'RUT', ...months.map(m => m.toUpperCase()), 'PROM'];
    const rowMatHeader = matriculasSheet.addRow(headerMat);
    rowMatHeader.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    sortedStudents.forEach(student => {
      const rowData = [
        student.nombre,
        student.rut,
        ...months.map(m => {
          const cellVal = formatExactDbValue(student[m]);
          return (cellVal !== '-' ? cellVal + '%' : cellVal);
        }),
        getStudentAverage(student)
      ];
      matriculasSheet.addRow(rowData);
    });
    if (globalAverages) {
      const totalRow = [];
      totalRow.push('PROMEDIO CURSO:');
      totalRow.push('');
      months.forEach(m => {
        totalRow.push(globalAverages[m]);
      });
      totalRow.push(globalAverages.total);
      const rowTotal = matriculasSheet.addRow(totalRow);
      rowTotal.eachCell((cell) => {
        cell.font = { bold: true };
      });
    }

    // ===== Hoja 2: Ranking Anual =====
    if (annualRanking) {
      const rankingAnualSheet = workbook.addWorksheet('Ranking Anual');
      const headerRA = ['Peor Asistencia (Top 5)', 'Mejor Asistencia (Top 5)'];
      const rowHeaderRA = rankingAnualSheet.addRow(headerRA);
      rowHeaderRA.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0070C0' }
        };
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
      for (let i = 0; i < 35; i++) {
        const worst = annualRanking.worst[i] ? `${annualRanking.worst[i].nombre} (${formatExactDbValue(annualRanking.worst[i].promedio)}%)` : '-';
        const best = annualRanking.best[i] ? `${annualRanking.best[i].nombre} (${formatExactDbValue(annualRanking.best[i].promedio)}%)` : '-';
        rankingAnualSheet.addRow([worst, best]);
      }
    }

    // ===== Hoja 3: Ranking Mensual =====
    if (selectedRankingMonth && monthlyRanking) {
      const rankingMensualSheet = workbook.addWorksheet('Ranking Mensual');
      const headerRM = ['Peor Asistencia', 'Mejor Asistencia'];
      const rowHeaderRM = rankingMensualSheet.addRow(headerRM);
      rowHeaderRM.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0070C0' }
        };
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
      for (let i = 0; i < 35; i++) {
        const worst = monthlyRanking.worst[i] ? `${monthlyRanking.worst[i].nombre} (${monthlyRanking.worst[i].value}%)` : '-';
        const best = monthlyRanking.best[i] ? `${monthlyRanking.best[i].nombre} (${monthlyRanking.best[i].value}%)` : '-';
        rankingMensualSheet.addRow([worst, best]);
      }
    }

    // ===== Hoja 4: Ausentismo Crónico =====
    if (selectedCronicMonth && ausentismoSummary) {
      const ausentismoSheet = workbook.addWorksheet('Ausentismo Crónico');
      const headerAC = ['Descripción', 'Valor'];
      const rowHeaderAC = ausentismoSheet.addRow(headerAC);
      rowHeaderAC.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0070C0' }
        };
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
      const { reiterado, cronicoGrave, muyGrave, totalPercent } = ausentismoSummary[selectedCronicMonth];
      ausentismoSheet.addRow(['Crónico Reiterado (85-89.9)', reiterado]);
      ausentismoSheet.addRow(['Crónico Grave (50-84.9)', cronicoGrave]);
      ausentismoSheet.addRow(['Muy Grave (<50)', muyGrave]);
      ausentismoSheet.addRow(['Total Ausentismo', totalPercent === '-' ? '-' : totalPercent + '%']);
    }

    // ===== Hoja 5: Progreso Mensual de Ausentismo =====
    if (chartData) {
      const progresoSheet = workbook.addWorksheet('Progreso Ausentismo');
      const headerPA = ['Mes', 'Ausentismo (%)'];
      const rowHeaderPA = progresoSheet.addRow(headerPA);
      rowHeaderPA.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF0070C0' }
        };
        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
      chartData.labels.forEach((mes, index) => {
        const valor = chartData.datasets[0].data[index];
        progresoSheet.addRow([mes, valor === null ? '-' : valor]);
      });
    }

    // ===== Hoja 6: Gráficos =====
    const graficosSheet = workbook.addWorksheet('Gráficos');
    graficosSheet.addRow(['Progreso Mensual de Ausentismo']);
    const headerGrafico = ['Mes', 'Ausentismo (%)'];
    const rowHeaderGrafico = graficosSheet.addRow(headerGrafico);
    rowHeaderGrafico.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    if (chartData) {
      chartData.labels.forEach((mes, index) => {
        const valor = chartData.datasets[0].data[index];
        graficosSheet.addRow([mes, valor === null ? '-' : valor]);
      });
    }
    graficosSheet.addRow([]);
    const donutData = getDonutChartData();
    graficosSheet.addRow(['Distribución de Asistencia']);
    const headerDonut = ['Categoría', 'Valor'];
    const rowHeaderDonut = graficosSheet.addRow(headerDonut);
    rowHeaderDonut.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    donutData.labels.forEach((label, index) => {
      const value = donutData.datasets[0].data[index];
      graficosSheet.addRow([label, value]);
    });

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `matriculas_${year}_${tipoEnsenanza}_${nivel}_${curso}.xlsx`);
  }

  return (
    <div className="matriculas-container" ref={exportRef}>
      <h1>Administración de Matrículas</h1>
      <div className="filters">
        <div className="filter">
          <label>Año:</label>
          <select value={year} onChange={e => setYear(e.target.value)}>
            <option value="2025">2025</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
          </select>
        </div>
        <div className="filter">
          <label>Tipo de Enseñanza:</label>
          <select value={tipoEnsenanza} onChange={e => setTipoEnsenanza(e.target.value)}>
            <option value="">Seleccione</option>
            <option value="10">10</option>
            <option value="110">110</option>
            <option value="310">310</option>
          </select>
        </div>
        <div className="filter">
          <label>Nivel:</label>
          <select value={nivel} onChange={e => setNivel(e.target.value)}>
            <option value="">Seleccione</option>
            {getNivelOptions().map(opt => (
              <option key={opt} value={opt}>{opt}</option>
            ))}
          </select>
        </div>
        <div className="filter">
          <label>Curso:</label>
          <select value={curso} onChange={e => setCurso(e.target.value)}>
            <option value="">Seleccione</option>
            <option value="A">A</option>
            <option value="B">B</option>
          </select>
        </div>
      </div>

      {loading && <p>Cargando estudiantes...</p>}
      {error && <p className="error">{error}</p>}

      <div className="students-list">
        <h2>Estudiantes Matriculados</h2>
        {students.length > 0 ? (
          <table className="students-table">
            <thead>
              <tr>
                <th onClick={() => requestSort('nombre')}>Nombre</th>
                <th onClick={() => requestSort('rut')}>RUT</th>
                {months.map(m => (
                  <th key={m} onClick={() => requestSort(m)}>{m.toUpperCase()}</th>
                ))}
                <th onClick={() => requestSort('promedio')}>PROM</th>
              </tr>
            </thead>
            <tbody>
              {sortedStudents.map((student) => (
                <tr key={student.estudiante_id}>
                  <td>{student.nombre}</td>
                  <td>{student.rut}</td>
                  {months.map(m => (
                    <td
                      key={m}
                      title={getAttendanceTooltip(
                        student[m],
                        student[m + '_present'],
                        student[m + '_absent']
                      )}
                    >
                      {formatExactDbValue(student[m])}
                      {(student[m] !== null &&
                        student[m] !== undefined &&
                        student[m] !== '-') ? '%' : ''}
                    </td>
                  ))}
                  <td>{getStudentAverage(student)}</td>
                </tr>
              ))}
            </tbody>
            {globalAverages && (
              <tfoot>
                <tr>
                  <td colSpan="2" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    PROMEDIO CURSO:
                  </td>
                  {months.map(m => (
                    <td key={m}>{globalAverages[m]}</td>
                  ))}
                  <td>{globalAverages.total}</td>
                </tr>
              </tfoot>
            )}
          </table>
        ) : (
          <p>No hay estudiantes para los filtros seleccionados.</p>
        )}
      </div>

      {globalAverages && (
        <div style={{ marginTop: '30px' }}>
          <h2>Asistencia del Curso Durante el Año</h2>
          <div className="charts-dual-container">
            <div className="chart-container">
              <Line
                data={{
                  labels: months.map(m => m.toUpperCase()),
                  datasets: [
                    {
                      label: 'Asistencia (%)',
                      data: months.map((m, i) => {
                        if (i > globalLastTaughtIndex) return null;
                        const val = globalAverages[m];
                        return val === '-' ? null : Number(val.replace('%',''));
                      }),
                      borderColor: 'rgba(54, 162, 235, 1)',
                      backgroundColor: 'rgba(54, 162, 235, 0.2)',
                      fill: true,
                      tension: 0.3
                    }
                  ]
                }}
              />
            </div>
            <div className="chart-container">
              <Doughnut data={getDonutChartData()} />
            </div>
          </div>
        </div>
      )}

      {annualRanking && (
        <div className="ranking-annual">
          <h2>Ranking Anual del Curso</h2>
          <table className="ranking-table">
            <thead>
              <tr>
                <th>Peor Asistencia (Top 5)</th>
                <th>Mejor Asistencia (Top 5)</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 35 }).map((_, i) => (
                <tr key={i}>
                  <td>{annualRanking.worst[i]?.nombre} ({formatExactDbValue(annualRanking.worst[i]?.promedio)}%)</td>
                  <td>{annualRanking.best[i]?.nombre} ({formatExactDbValue(annualRanking.best[i]?.promedio)}%)</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {students.length > 0 && (
        <div className="ranking-monthly">
          <h2>Ranking Mensual</h2>
          <div className="filter">
            <label>Seleccione Mes:</label>
            <select
              value={selectedRankingMonth}
              onChange={e => setSelectedRankingMonth(e.target.value)}
            >
              <option value="">Seleccione</option>
              {months.map(m => (
                <option key={m} value={m}>{m.toUpperCase()}</option>
              ))}
            </select>
          </div>
          {selectedRankingMonth && monthlyRanking && (
            <div className="ranking-table-container">
              <table className="ranking-table">
                <thead>
                  <tr>
                    <th>Peor Asistencia</th>
                    <th>Mejor Asistencia</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 35 }).map((_, i) => (
                    <tr key={i}>
                      <td>{monthlyRanking.worst[i]?.nombre} ({monthlyRanking.worst[i]?.value}%)</td>
                      <td>{monthlyRanking.best[i]?.nombre} ({monthlyRanking.best[i]?.value}%)</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {ausentismoSummary && (
        <div className="ausentismo-chronica">
          <h2>Ausentismo Crónico</h2>
          <div className="filter">
            <label>Seleccione Mes de Ausentismo:</label>
            <select
              value={selectedCronicMonth}
              onChange={e => setSelectedCronicMonth(e.target.value)}
            >
              <option value="">Seleccione</option>
              {calculationMonths.map(m => (
                <option key={m} value={m}>{m.toUpperCase()}</option>
              ))}
            </select>
          </div>
          {selectedCronicMonth && (
            <div className="ausentismo-summary-container">
              {(() => {
                const { reiterado, cronicoGrave, muyGrave, totalPercent } = ausentismoSummary[selectedCronicMonth];
                return (
                  <div className="ausentismo-summary-box">
                    <h3>{selectedCronicMonth.toUpperCase()}</h3>
                    <p>
                      <strong>Crónico Reiterado (entre 85 y 89,9): </strong>
                      {reiterado}
                    </p>
                    <p>
                      <strong>Crónico Grave (entre 50 y 84,9): </strong>
                      {cronicoGrave}
                    </p>
                    <p>
                      <strong>Muy Grave (menor que 50): </strong>
                      {muyGrave}
                    </p>
                    <p>
                      <strong>Total Ausentismo: </strong>
                      {totalPercent === '-' ? '-' : totalPercent + '%'}
                    </p>
                  </div>
                );
              })()}
            </div>
          )}
        </div>
      )}

      {chartData && (
        <div style={{ marginTop: '30px' }}>
          <h2>Progreso Mensual de Ausentismo</h2>
          <Line data={chartData} />
        </div>
      )}

      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <button className="create-button" onClick={exportPDF}>Exportar PDF</button>
        <button className="create-button" onClick={exportExcel} style={{ marginLeft: '20px' }}>
          Exportar Excel
        </button>
      </div>
    </div>
  );
}
