import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import betterlandApp from './betterlandApp.webp';

function Sidebar() {
  const role = localStorage.getItem('role');
  console.log("Sidebar - Rol actual:", role);

  // Configurar apertura de acordeones: para "Convivencia" se abre el acordeón de Atrasos por defecto.
  const initialAtrasosOpen = (role && role.toLowerCase() === 'convivencia') ? true : false;
  const [isAtrasosOpen, setIsAtrasosOpen] = useState(initialAtrasosOpen);
  const [isFluidezOpen, setIsFluidezOpen] = useState(false);

  const toggleAtrasos = () => {
    setIsAtrasosOpen(!isAtrasosOpen);
  };

  const toggleFluidez = () => {
    setIsFluidezOpen(!isFluidezOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    console.log("Token y rol eliminados. Rol actual:", localStorage.getItem('role'));
    window.location.href = '/login';
  };

  // Interceptor de axios para redirigir al login cuando el token expire (status 403)
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 403) {
          // El token ha expirado o es inválido: limpiamos el storage y redirigimos
          localStorage.removeItem('token');
          localStorage.removeItem('role');
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );
    // Eject del interceptor al desmontar el componente
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  // Rol Convivencia: Solo muestra el menú Atrasos
  if (role && role.toLowerCase() === 'convivencia') {
    return (
      <div className="sidebar">
        <img src={betterlandApp} alt="Betterland App" className="header-image" />
        <nav>
          <div className="accordion">
            <button className="accordion-button white-text" onClick={toggleAtrasos}>
              Atrasos
            </button>
            {isAtrasosOpen && (
              <div className="accordion-content">
                <NavLink 
                  to="/Atrasos" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Atrasos Entrada
                </NavLink>
                <NavLink 
                  to="/AtrasosAula" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Atrasos Aula
                </NavLink>
              </div>
            )}
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </nav>
      </div>
    );
  }

  // Rol Profesor Jefe: Solo muestra Estadísticas Individuales, Atrasos y Programa de Fluidez
  if (role === 'Profesor Jefe') {
    return (
      <div className="sidebar">
        <img src={betterlandApp} alt="Betterland App" className="header-image" />
        <nav>
          <NavLink 
            to="/matricula" 
            className={({ isActive }) => isActive ? "active-link" : ""}
          >
            Estadísticas Individuales
          </NavLink>
          <div className="accordion">
            <button className="accordion-button white-text" onClick={toggleAtrasos}>
              Atrasos
            </button>
            {isAtrasosOpen && (
              <div className="accordion-content">
                <NavLink 
                  to="/Atrasos" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Atrasos Entrada
                </NavLink>
                <NavLink 
                  to="/AtrasosAula" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Atrasos Aula
                </NavLink>
              </div>
            )}
          </div>
          <div className="accordion">
            <button className="accordion-button white-text" onClick={toggleFluidez}>
              Programa de Fluidez
            </button>
            {isFluidezOpen && (
              <div className="accordion-content">
                <NavLink 
                  to="/resumen-fluidez" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Resumen Fluidez
                </NavLink>
              </div>
            )}
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </nav>
      </div>
    );
  }

  // Rol Fluidez: Solo muestra el menú Programa de Fluidez
  if (role === 'Fluidez') {
    return (
      <div className="sidebar">
        <img src={betterlandApp} alt="Betterland App" className="header-image" />
        <nav>
          <div className="accordion">
            <button className="accordion-button white-text" onClick={toggleFluidez}>
              Programa de Fluidez
            </button>
            {isFluidezOpen && (
              <div className="accordion-content">
                <NavLink 
                  to="/fluidez-lectora" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Fluidez Lectora
                </NavLink>
                <NavLink 
                  to="/fluidez-matematicas" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Fluidez Matemáticas
                </NavLink>
                <NavLink 
                  to="/resumen-fluidez" 
                  className={({ isActive }) => isActive ? "active-link" : ""}
                >
                  Resumen Fluidez
                </NavLink>
              </div>
            )}
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </nav>
      </div>
    );
  }

  // Para Admin, Data Manager y Viewer:
  return (
    <div className="sidebar">
      <img src={betterlandApp} alt="Betterland App" className="header-image" />
      <nav>
        <NavLink 
          to="/dashboard" 
          className={({ isActive }) => isActive ? "active-link" : ""}
        >
          Dashboard
        </NavLink>
        <NavLink 
          to="/estadisticas" 
          className={({ isActive }) => isActive ? "active-link" : ""}
        >
          Estadísticas Diarias
        </NavLink>
        <NavLink 
          to="/matricula" 
          className={({ isActive }) => isActive ? "active-link" : ""}
        >
          Estadísticas Individuales
        </NavLink>
        <div className="accordion">
          <button className="accordion-button white-text" onClick={toggleAtrasos}>
            Atrasos
          </button>
          {isAtrasosOpen && (
            <div className="accordion-content">
              <NavLink 
                to="/Atrasos" 
                className={({ isActive }) => isActive ? "active-link" : ""}
              >
                Atrasos Entrada
              </NavLink>
              <NavLink 
                to="/AtrasosAula" 
                className={({ isActive }) => isActive ? "active-link" : ""}
              >
                Atrasos de Aula
              </NavLink>
            </div>
          )}
        </div>
        <div className="accordion">
          <button className="accordion-button white-text" onClick={toggleFluidez}>
            Programa de Fluidez
          </button>
          {isFluidezOpen && (
            <div className="accordion-content">
              <NavLink 
                to="/fluidez-lectora" 
                className={({ isActive }) => isActive ? "active-link" : ""}
              >
                Fluidez Lectora
              </NavLink>
              <NavLink 
                to="/fluidez-matematicas" 
                className={({ isActive }) => isActive ? "active-link" : ""}
              >
                Fluidez Matemáticas
              </NavLink>
              <NavLink 
                to="/resumen-fluidez" 
                className={({ isActive }) => isActive ? "active-link" : ""}
              >
                Resumen Fluidez
              </NavLink>
            </div>
          )}
        </div>
        {(role === 'Data Manager' || role === 'Admin') && (
          <NavLink 
            to="/carga-asistencia" 
            className={({ isActive }) => isActive ? "active-link" : ""}
          >
            Carga Asistencia
          </NavLink>
        )}
        {role === 'Admin' && (
          <NavLink 
            to="/gestion-usuarios" 
            className={({ isActive }) => isActive ? "active-link" : ""}
          >
            Gestión de Usuarios
          </NavLink>
        )}
        <button className="logout-button" onClick={handleLogout}>
          Cerrar Sesión
        </button>
      </nav>
    </div>
  );
}

export default Sidebar;
