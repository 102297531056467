import React, { useState, useEffect, useRef } from 'react';
import './PageStyles.css';

const levelOptions = [
  '5A','5B','6A','6B','7A','7B','8A','8B','IA','IB','IIA','IIB','IIIA','IIIB','IVA','IVB'
];

const gradeFields = [
  'math_eval1', 'math_eval1a',
  'math_eval2', 'math_eval2a',
  'math_eval3', 'math_eval3a',
  'math_eval4', 'math_eval4a'
];

const FluidezMatematicas = () => {
  const [filterYear, setFilterYear] = useState('');
  const [filterNivel, setFilterNivel] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  
  // Referencia para el input de archivo CSV (oculto)
  const fileInputRef = useRef(null);

  const safeTrim = (value) => {
    return value === null || value === undefined ? '' : String(value).trim();
  };

  const fetchStudents = async () => {
    if (!filterYear || !filterNivel) return;
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `https://asistencia-betterland.onrender.com/fluidez/estudiantes?yearFluidez=${filterYear}&nivel=${filterNivel}`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      if (response.ok) {
        const data = await response.json();
        data.sort((a, b) => {
          if (a.lastnamepaternal < b.lastnamepaternal) return -1;
          if (a.lastnamepaternal > b.lastnamepaternal) return 1;
          return 0;
        });
        const modifiedData = data.map(student => ({
          ...student,
          math_eval1: student.math_eval1 === null ? '' : student.math_eval1,
          math_eval1a: student.math_eval1a === null ? '' : student.math_eval1a,
          math_eval2: student.math_eval2 === null ? '' : student.math_eval2,
          math_eval2a: student.math_eval2a === null ? '' : student.math_eval2a,
          math_eval3: student.math_eval3 === null ? '' : student.math_eval3,
          math_eval3a: student.math_eval3a === null ? '' : student.math_eval3a,
          math_eval4: student.math_eval4 === null ? '' : student.math_eval4,
          math_eval4a: student.math_eval4a === null ? '' : student.math_eval4a
        }));
        console.log("Estudiantes recibidos:", modifiedData);
        setStudents(modifiedData);
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.error("Error al cargar estudiantes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, index, field) => {
    const newStudents = [...students];
    newStudents[index][field] = e.target.value;
    setStudents(newStudents);
  };

  const handleKeyDown = (e, rowIndex, field) => {
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      e.preventDefault();
    } else {
      return;
    }
    const currentFieldIndex = gradeFields.indexOf(field);
    let targetRow = rowIndex;
    let targetFieldIndex = currentFieldIndex;
    switch(e.key) {
      case 'ArrowRight':
        if (currentFieldIndex < gradeFields.length - 1) {
          targetFieldIndex = currentFieldIndex + 1;
        }
        break;
      case 'ArrowLeft':
        if (currentFieldIndex > 0) {
          targetFieldIndex = currentFieldIndex - 1;
        }
        break;
      case 'ArrowUp':
        if (rowIndex > 0) {
          targetRow = rowIndex - 1;
        }
        break;
      case 'ArrowDown':
        if (rowIndex < students.length - 1) {
          targetRow = rowIndex + 1;
        }
        break;
      default:
        break;
    }
    const targetField = gradeFields[targetFieldIndex];
    const targetInput = document.getElementById(`input-${targetRow}-${targetField}`);
    if (targetInput) {
      targetInput.focus();
    }
  };

  const handleSave = async (student) => {
    try {
      const token = localStorage.getItem('token');
      const eval1 = safeTrim(student.math_eval1) === '' ? null : Number(safeTrim(student.math_eval1));
      const eval1a = safeTrim(student.math_eval1a) === '' ? null : Number(safeTrim(student.math_eval1a));
      const eval2 = safeTrim(student.math_eval2) === '' ? null : Number(safeTrim(student.math_eval2));
      const eval2a = safeTrim(student.math_eval2a) === '' ? null : Number(safeTrim(student.math_eval2a));
      const eval3 = safeTrim(student.math_eval3) === '' ? null : Number(safeTrim(student.math_eval3));
      const eval3a = safeTrim(student.math_eval3a) === '' ? null : Number(safeTrim(student.math_eval3a));
      const eval4 = safeTrim(student.math_eval4) === '' ? null : Number(safeTrim(student.math_eval4));
      const eval4a = safeTrim(student.math_eval4a) === '' ? null : Number(safeTrim(student.math_eval4a));
      const response = await fetch(
        `https://asistencia-betterland.onrender.com/fluidez/estudiantes/${student.rut}/evaluaciones/math`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            yearFluidez: filterYear,
            math_eval1: eval1, math_eval1a: eval1a,
            math_eval2: eval2, math_eval2a: eval2a,
            math_eval3: eval3, math_eval3a: eval3a,
            math_eval4: eval4, math_eval4a: eval4a
          })
        }
      );
      if (response.ok) {
        setUpdateMessage(`Evaluaciones actualizadas para ${student.firstname} ${student.lastnamepaternal}`);
        fetchStudents();
      } else {
        setUpdateMessage("Error al actualizar evaluaciones.");
      }
    } catch (error) {
      console.error("Error al actualizar:", error);
      setUpdateMessage("Error al conectar con el servidor.");
    }
  };

  const handleSaveAll = async () => {
    try {
      const token = localStorage.getItem('token');
      const promises = students.map(student => {
        const eval1 = safeTrim(student.math_eval1) === '' ? null : Number(safeTrim(student.math_eval1));
        const eval1a = safeTrim(student.math_eval1a) === '' ? null : Number(safeTrim(student.math_eval1a));
        const eval2 = safeTrim(student.math_eval2) === '' ? null : Number(safeTrim(student.math_eval2));
        const eval2a = safeTrim(student.math_eval2a) === '' ? null : Number(safeTrim(student.math_eval2a));
        const eval3 = safeTrim(student.math_eval3) === '' ? null : Number(safeTrim(student.math_eval3));
        const eval3a = safeTrim(student.math_eval3a) === '' ? null : Number(safeTrim(student.math_eval3a));
        const eval4 = safeTrim(student.math_eval4) === '' ? null : Number(safeTrim(student.math_eval4));
        const eval4a = safeTrim(student.math_eval4a) === '' ? null : Number(safeTrim(student.math_eval4a));
        return fetch(
          `https://asistencia-betterland.onrender.com/fluidez/estudiantes/${student.rut}/evaluaciones/math`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
              yearFluidez: filterYear,
              math_eval1: eval1, math_eval1a: eval1a,
              math_eval2: eval2, math_eval2a: eval2a,
              math_eval3: eval3, math_eval3a: eval3a,
              math_eval4: eval4, math_eval4a: eval4a
            })
          }
        );
      });
      const results = await Promise.all(promises);
      const allOk = results.every(res => res.ok);
      if (allOk) {
        setUpdateMessage("Evaluaciones actualizadas para todos los estudiantes.");
        fetchStudents();
      } else {
        setUpdateMessage("Error al actualizar algunas evaluaciones.");
      }
    } catch (error) {
      console.error("Error al actualizar en lote:", error);
      setUpdateMessage("Error al conectar con el servidor para guardar todo.");
    }
  };

  const exportCSV = () => {
    if (students.length === 0) return;
    const headers = [
      "Nivel",
      "RUT",
      "Apellido Paterno",
      "Apellido Materno",
      "Nombres",
      "Fluidez Matemáticas 1",
      "Fluidez Matemáticas 1a",
      "Fluidez Matemáticas 2",
      "Fluidez Matemáticas 2a",
      "Fluidez Matemáticas 3",
      "Fluidez Matemáticas 3a",
      "Fluidez Matemáticas 4",
      "Fluidez Matemáticas 4a"
    ];
    const rows = students.map(stu => [
      stu.nivel,
      stu.rut,
      stu.lastnamepaternal,
      stu.lastnamematernal,
      stu.firstname,
      stu.math_eval1,
      stu.math_eval1a,
      stu.math_eval2,
      stu.math_eval2a,
      stu.math_eval3,
      stu.math_eval3a,
      stu.math_eval4,
      stu.math_eval4a
    ]);
    const csvContent =
      "\uFEFF" + headers.join(";") + "\n" + rows.map(row => row.join(";")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `fluidez_matematicas_${filterYear}_${filterNivel}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Función para importar CSV
  const handleImportCSV = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const cleanText = text.replace(/^\uFEFF/, '');
      const lines = cleanText.split("\n").filter(line => line.trim() !== "");
      if (lines.length === 0) return;
      const headers = lines[0].split(";").map(h => h.trim());
      const data = lines.slice(1).map(line => {
        const values = line.split(";").map(v => v.trim());
        const obj = {};
        headers.forEach((header, i) => {
          obj[header] = values[i];
        });
        return obj;
      });
      // Mapeamos los datos al formato de estudiante
      const importedStudents = data.map(item => ({
        nivel: item["Nivel"],
        rut: item["RUT"],
        lastnamepaternal: item["Apellido Paterno"],
        lastnamematernal: item["Apellido Materno"],
        firstname: item["Nombres"],
        math_eval1: item["Fluidez Matemáticas 1"],
        math_eval1a: item["Fluidez Matemáticas 1a"],
        math_eval2: item["Fluidez Matemáticas 2"],
        math_eval2a: item["Fluidez Matemáticas 2a"],
        math_eval3: item["Fluidez Matemáticas 3"],
        math_eval3a: item["Fluidez Matemáticas 3a"],
        math_eval4: item["Fluidez Matemáticas 4"],
        math_eval4a: item["Fluidez Matemáticas 4a"]
      }));
      setStudents(importedStudents);
    };
    reader.readAsText(file, "UTF-8");
  };

  useEffect(() => {
    fetchStudents();
  }, [filterYear, filterNivel]);

  return (
    <div className="page-content">
      <h2 className="page-title">Fluidez Matemáticas</h2>

      <div className="card filters-container">
        <div className="filter">
          <label>Año:</label>
          <select value={filterYear} onChange={e => setFilterYear(e.target.value)}>
            <option value="">Seleccione el año</option>
            <option value="2025">2025</option>
          </select>
        </div>
        <div className="filter">
          <label>Nivel:</label>
          <select value={filterNivel} onChange={e => setFilterNivel(e.target.value)}>
            <option value="">Seleccione Nivel</option>
            {levelOptions.map(level => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>
        <button className="create-button" onClick={fetchStudents}>
          Buscar
        </button>
      </div>

      {loading ? (
        <p>Cargando estudiantes...</p>
      ) : (
        students.length > 0 && (
          <div className="card">
            <div className="table-header">
              <h3>
                {filterNivel ? `Lista de Estudiantes del ${filterNivel}` : "Lista de Estudiantes"}
              </h3>
              <button className="create-button general-save-button" onClick={handleSaveAll}>
                Guardar Todo
              </button>
            </div>
            {updateMessage && <p className="message">{updateMessage}</p>}
            <table className="fluidez-table">
              <thead>
                <tr>
                  <th>Nivel</th>
                  <th>RUT</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nombres</th>
                  <th>Nota 1 +</th>
                  <th>Nota 1 x</th>
                  <th>Nota 2 +</th>
                  <th>Nota 2 x</th>
                  <th>Nota 3 +</th>
                  <th>Nota 3 x</th>
                  <th>Nota 4 +</th>
                  <th>Nota 4 x</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {students.map((stu, index) => (
                  <tr key={stu.rut}>
                    <td>{stu.nivel}</td>
                    <td>{stu.rut}</td>
                    <td>{stu.lastnamepaternal}</td>
                    <td>{stu.lastnamematernal}</td>
                    <td>{stu.firstname}</td>
                    {gradeFields.map((field) => (
                      <td key={field}>
                        <input
                          id={`input-${index}-${field}`}
                          type="number"
                          value={stu[field]}
                          onChange={e => handleInputChange(e, index, field)}
                          onKeyDown={e => handleKeyDown(e, index, field)}
                          onWheel={e => e.preventDefault()}
                          style={{ width: '40px', fontSize: '0.8rem' }}
                        />
                      </td>
                    ))}
                    <td>
                      <button className="create-button" onClick={() => handleSave(stu)}>
                        Guardar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ marginTop: '1rem' }}>
              <button className="create-button export-csv-button" onClick={exportCSV}>
                Exportar CSV
              </button>
              <button
                className="create-button import-csv-button"
                onClick={() => fileInputRef.current && fileInputRef.current.click()}
                style={{ marginLeft: '1rem' }}
              >
                Importar CSV
              </button>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImportCSV}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FluidezMatematicas;
