import React, { useState } from 'react';
import './Login.css';
import betterlandApp from './betterlandApp.webp'; // Ajusta la ruta si es necesario

// Función para decodificar el token JWT y obtener su payload
function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error("Error al decodificar el token:", e);
    return null;
  }
}

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://asistencia-betterland.onrender.com/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log("Respuesta del login:", data);

      if (response.ok) {
        console.log("Token recibido:", data.token);
        const tokenPayload = parseJwt(data.token);
        console.log("Token payload:", tokenPayload);
        let roleReceived = tokenPayload ? tokenPayload.role : null;
        console.log("Rol recibido del token:", roleReceived);

        let roleToStore = roleReceived;
        if (roleReceived === "data_manager") {
          roleToStore = "Data Manager";
        } else if (roleReceived === "viewer") {
          roleToStore = "Viewer";
        } else if (roleReceived === "admin") {
          roleToStore = "Admin";
        } else if (roleReceived === "profesor_jefe") {
          roleToStore = "Profesor Jefe";
        } else if (roleReceived === "fluidez") {
          roleToStore = "Fluidez";
        }
        console.log("Rol a almacenar:", roleToStore);

        localStorage.setItem('token', data.token);
        localStorage.setItem('role', roleToStore);
        alert('Inicio de sesión exitoso');

        if (roleToStore === "Profesor Jefe") {
          window.location.href = '/matricula';
        } else if (roleToStore === "Fluidez") {
          window.location.href = '/resumen-fluidez';
        } else {
          window.location.href = '/dashboard';
        }
      } else {
        setError(data.message || 'Error en el inicio de sesión');
      }
    } catch (err) {
      console.error("Error al conectar con el servidor:", err);
      setError('Error al conectar con el servidor');
    }
  };

  return (
    <div className="login-container">
      {/* Imagen agregada encima del contenedor de inicio de sesión */}
      <img src={betterlandApp} alt="Betterland App" className="login-header-image" />
      <div className="login-card">
        <h2>Iniciar Sesión</h2>
        <form onSubmit={handleLogin} className="login-form">
          <input 
            type="text" 
            placeholder="Usuario" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
          <input 
            type="password" 
            placeholder="Contraseña" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <button type="submit">Ingresar</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
