import React, { useState, useEffect } from 'react';
import './PageStyles.css';

const CargaAsistencia = () => {
  // Estados para Asistencia
  const [file, setFile] = useState(null);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  // Estados para Datos de Estudiantes por CSV
  const [studentFile, setStudentFile] = useState(null);
  const [studentYear, setStudentYear] = useState('');
  const [studentMessage, setStudentMessage] = useState('');
  const [studentUploading, setStudentUploading] = useState(false);

  // Estados para Administración de Estudiantes (tabla)
  const [filterYear, setFilterYear] = useState('');
  const [filterNivel, setFilterNivel] = useState('');
  const [students, setStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);

  // Estados para Agregar Estudiante Manualmente (fluidez)
  const [manualStudent, setManualStudent] = useState({
    yearFluidez: '',
    rut: '',
    firstName: '',
    lastNamePaternal: '',
    lastNameMaternal: '',
    nivel: ''
  });
  const [manualMessage, setManualMessage] = useState('');

  // Lista de años para los selects
  const years = ['', '2025', '2024', '2023', '2022'];

  // =============================
  // Sección de Asistencia
  // =============================
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (!file || !year || !month) {
      setMessage('Por favor, complete todos los campos de asistencia.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('year', year);
    formData.append('month', month);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://asistencia-betterland.onrender.com/asistencia/upload', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        setMessage('Archivo de asistencia cargado con éxito.');
        setFile(null);
        setYear('');
        setMonth('');
      } else {
        setMessage('Error al cargar el archivo de asistencia.');
      }
    } catch (error) {
      setMessage('Error al conectar con el servidor para asistencia.');
    } finally {
      setUploading(false);
    }
  };

  // =============================
  // Sección de Datos de Estudiantes (CSV) - Fluidez
  // =============================
  const handleStudentFileChange = (e) => {
    setStudentFile(e.target.files[0]);
  };

  const handleStudentUpload = async (e) => {
    e.preventDefault();
    setStudentMessage('');

    if (!studentFile || !studentYear) {
      setStudentMessage('Por favor, complete todos los campos de datos de estudiantes.');
      return;
    }

    setStudentUploading(true);
    const formData = new FormData();
    formData.append('file', studentFile);
    formData.append('year', studentYear);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://asistencia-betterland.onrender.com/fluidez/estudiantes/upload', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        setStudentMessage('Datos de estudiantes cargados con éxito.');
        setStudentFile(null);
        setStudentYear('');
      } else {
        setStudentMessage('Error al cargar el archivo de estudiantes.');
      }
    } catch (error) {
      setStudentMessage('Error al conectar con el servidor para datos de estudiantes.');
    } finally {
      setStudentUploading(false);
    }
  };

  // Función para descargar la plantilla CSV
  const downloadCSVTemplate = () => {
    const csvContent = "\uFEFF" + "año;rut;nombres;apellido_paterno;apellido_materno;nivel\n2025;;;;;\n";
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "plantilla_estudiantes.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Función para descargar el log desde el backend
  const downloadLog = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://asistencia-betterland.onrender.com/logs/download', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) {
        throw new Error('Error al descargar el log');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'fluidez-changes.log';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error descargando el log:', error);
    }
  };

  // =============================
  // Sección de Administración de Estudiantes
  // =============================
  const fetchStudents = async () => {
    if (!filterYear) return;
    setLoadingStudents(true);
    try {
      const token = localStorage.getItem('token');
      let url = `https://asistencia-betterland.onrender.com/fluidez/estudiantes?yearFluidez=${filterYear}`;
      if (filterNivel) {
        url += `&nivel=${filterNivel}`;
      }
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        data.sort((a, b) => a.lastnamepaternal.localeCompare(b.lastnamepaternal));
        setStudents(data);
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.error("Error al cargar estudiantes:", error);
    } finally {
      setLoadingStudents(false);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, [filterYear, filterNivel]);

  const deleteStudent = async (rut) => {
    if (!filterYear) return;
    const confirmDelete = window.confirm("¿Está seguro de eliminar este estudiante?");
    if (!confirmDelete) return;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://asistencia-betterland.onrender.com/fluidez/estudiantes?yearFluidez=${filterYear}&rut=${rut}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        fetchStudents();
      } else {
        alert("Error al eliminar el estudiante");
      }
    } catch (error) {
      console.error("Error al eliminar estudiante:", error);
    }
  };

  const handleManualChange = (e) => {
    const { name, value } = e.target;
    setManualStudent(prev => ({ ...prev, [name]: value }));
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    setManualMessage('');
    const { yearFluidez, rut, firstName, lastNamePaternal, lastNameMaternal, nivel } = manualStudent;
    if (!yearFluidez || !rut || !firstName || !lastNamePaternal || !lastNameMaternal || !nivel) {
      setManualMessage("Por favor, complete todos los campos del formulario.");
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://asistencia-betterland.onrender.com/fluidez/estudiantes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(manualStudent)
      });
      if (response.ok) {
        setManualMessage("Estudiante agregado exitosamente.");
        setManualStudent({
          yearFluidez: '',
          rut: '',
          firstName: '',
          lastNamePaternal: '',
          lastNameMaternal: '',
          nivel: ''
        });
        fetchStudents();
      } else {
        setManualMessage("Error al agregar el estudiante.");
      }
    } catch (error) {
      console.error("Error al agregar estudiante manual:", error);
      setManualMessage("Error al conectar con el servidor.");
    }
  };

  return (
    <div className="page-content">
      <h2 className="page-title">Carga de Asistencia y Datos de Estudiantes</h2>
      
      {/* Primera fila: Subir Archivo de Asistencia y Datos de Estudiantes Fluidez */}
      <div style={{ display: 'flex', gap: '1rem' }}>
        {/* Tarjeta: Subir Archivo de Asistencia */}
        <div className="card" style={{ flex: 1 }}>
          <h3>Subir Archivo de Asistencia</h3>
          <form onSubmit={handleSubmit} className="user-form">
            <div className="form-group">
              <label>Año</label>
              <select value={year} onChange={(e) => setYear(e.target.value)} required>
                {years.map((yr, index) => (
                  <option key={index} value={yr}>{yr === '' ? 'Seleccione el año' : yr}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Mes</label>
              <select value={month} onChange={(e) => setMonth(e.target.value)} required>
                <option value="">Seleccione el mes</option>
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </div>
            <div className="form-group">
              <label>Archivo de Asistencia</label>
              <input type="file" onChange={handleFileChange} required />
            </div>
            <button type="submit" className="create-button" disabled={uploading}>
              {uploading ? 'Subiendo...' : 'Subir Archivo'}
            </button>
          </form>
          {uploading && <div className="spinner"></div>}
          {message && <p className="message">{message}</p>}
        </div>

        {/* Tarjeta: Subir Archivo de Datos de Estudiantes Fluidez */}
        <div className="card" style={{ flex: 1 }}>
          <h3>Subir Archivo de Datos de Estudiantes Fluidez</h3>
          <button onClick={downloadCSVTemplate} className="download-button">
            Descargar Plantilla CSV
          </button>
          <form onSubmit={handleStudentUpload} className="user-form">
            <div className="form-group">
              <label>Año de Datos</label>
              <select value={studentYear} onChange={(e) => setStudentYear(e.target.value)} required>
                {years.map((yr, index) => (
                  <option key={index} value={yr}>{yr === '' ? 'Seleccione el año' : yr}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Archivo de Estudiantes (CSV)</label>
              <input type="file" onChange={handleStudentFileChange} accept=".csv" required />
            </div>
            <button type="submit" className="create-button" disabled={studentUploading}>
              {studentUploading ? 'Subiendo...' : 'Subir Datos de Estudiantes'}
            </button>
          </form>
          {studentUploading && <div className="spinner"></div>}
          {studentMessage && <p className="message">{studentMessage}</p>}
          {/* Nuevo botón para descargar el log */}
          <button onClick={downloadLog} className="create-button" style={{ marginTop: '1rem' }}>
            Descargar Log
          </button>
        </div>
      </div>

      {/* Segunda fila: Administración de Estudiantes y Agregar Estudiante Manualmente */}
      <div style={{ display: 'flex', gap: '1rem', marginTop: '2rem' }}>
        {/* Tarjeta: Administración de Estudiantes */}
        <div className="card" style={{ flex: 1 }}>
          <h3>Administración de Estudiantes</h3>
          <div className="form-group">
            <label>Filtrar por Año</label>
            <select value={filterYear} onChange={(e) => setFilterYear(e.target.value)}>
              {years.map((yr, index) => (
                <option key={index} value={yr}>{yr === '' ? 'Seleccione el año' : yr}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Filtrar por Nivel</label>
            <input
              type="text"
              placeholder="Nivel (ej: 1A, 1B, etc.)"
              value={filterNivel}
              onChange={(e) => setFilterNivel(e.target.value)}
            />
          </div>
          <button className="create-button" onClick={fetchStudents}>
            Cargar Estudiantes
          </button>
          
          {loadingStudents ? (
            <div className="spinner"></div>
          ) : (
            <table className="data-table">
              <thead>
                <tr>
                  <th>RUT</th>
                  <th>Nombres</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nivel</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {students.map((stu) => (
                  <tr key={stu.rut}>
                    <td>{stu.rut}</td>
                    <td>{stu.firstname}</td>
                    <td>{stu.lastnamepaternal}</td>
                    <td>{stu.lastnamematernal}</td>
                    <td>{stu.nivel}</td>
                    <td>
                      <button className="delete-button" onClick={() => deleteStudent(stu.rut)}>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
                {students.length === 0 && (
                  <tr>
                    <td colSpan="6">No se encontraron estudiantes.</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        {/* Tarjeta: Agregar Estudiante Manualmente */}
        <div className="card" style={{ flex: 1 }}>
          <h3>Agregar Estudiante Manualmente</h3>
          <form onSubmit={handleManualSubmit} className="user-form">
            <div className="form-group">
              <label>Año de Datos</label>
              <select name="yearFluidez" value={manualStudent.yearFluidez} onChange={handleManualChange} required>
                {years.map((yr, index) => (
                  <option key={index} value={yr}>{yr === '' ? 'Seleccione el año' : yr}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>RUT</label>
              <input
                type="text"
                name="rut"
                placeholder="Ingrese el RUT"
                value={manualStudent.rut}
                onChange={handleManualChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Nombres</label>
              <input
                type="text"
                name="firstName"
                placeholder="Ingrese los nombres"
                value={manualStudent.firstName}
                onChange={handleManualChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Apellido Paterno</label>
              <input
                type="text"
                name="lastNamePaternal"
                placeholder="Ingrese el apellido paterno"
                value={manualStudent.lastNamePaternal}
                onChange={handleManualChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Apellido Materno</label>
              <input
                type="text"
                name="lastNameMaternal"
                placeholder="Ingrese el apellido materno"
                value={manualStudent.lastNameMaternal}
                onChange={handleManualChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Nivel</label>
              <input
                type="text"
                name="nivel"
                placeholder="Ej: 1A, 1B, etc."
                value={manualStudent.nivel}
                onChange={handleManualChange}
                required
              />
            </div>
            <button type="submit" className="create-button">
              Agregar Estudiante
            </button>
          </form>
          {manualMessage && <p className="message">{manualMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default CargaAsistencia;
