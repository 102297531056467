import React, { useState, useEffect, useRef } from 'react';
import './PageStyles.css';

const levelOptions = [
  '1A','1B','2A','2B','3A','3B','4A','4B','5A','5B','6A','6B','7A','7B','8A','8B','IA','IB','IIA','IIB'
];

const readingFields = [
  'reading_eval1',
  'reading_eval2',
  'reading_eval3',
  'reading_eval4'
];

const FluidezLectora = () => {
  const [filterYear, setFilterYear] = useState('');
  const [filterNivel, setFilterNivel] = useState('');
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');
  
  // Referencia para el input file
  const fileInputRef = useRef(null);

  const getValue = (value) => {
    if (value === null || value === undefined) return null;
    const str = String(value).trim();
    return str === '' ? null : Number(str);
  };

  const fetchStudents = async () => {
    if (!filterYear || !filterNivel) return;
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://asistencia-betterland.onrender.com/fluidez/estudiantes?yearFluidez=${filterYear}&nivel=${filterNivel}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (response.ok) {
        const data = await response.json();
        data.sort((a, b) => {
          if (a.lastnamepaternal < b.lastnamepaternal) return -1;
          if (a.lastnamepaternal > b.lastnamepaternal) return 1;
          return 0;
        });
        const modifiedData = data.map(student => ({
          ...student,
          reading_eval1: student.reading_eval1 === null ? '' : student.reading_eval1,
          reading_eval2: student.reading_eval2 === null ? '' : student.reading_eval2,
          reading_eval3: student.reading_eval3 === null ? '' : student.reading_eval3,
          reading_eval4: student.reading_eval4 === null ? '' : student.reading_eval4
        }));
        console.log("Estudiantes recibidos:", modifiedData);
        setStudents(modifiedData);
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.error("Error al cargar estudiantes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, index, field) => {
    const newStudents = [...students];
    newStudents[index][field] = e.target.value;
    setStudents(newStudents);
  };

  const handleKeyDown = (e, rowIndex, field) => {
    if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      e.preventDefault();
    } else {
      return;
    }
    const currentFieldIndex = readingFields.indexOf(field);
    let targetRow = rowIndex;
    let targetFieldIndex = currentFieldIndex;
    switch(e.key) {
      case 'ArrowRight':
        if (currentFieldIndex < readingFields.length - 1) {
          targetFieldIndex = currentFieldIndex + 1;
        }
        break;
      case 'ArrowLeft':
        if (currentFieldIndex > 0) {
          targetFieldIndex = currentFieldIndex - 1;
        }
        break;
      case 'ArrowUp':
        if (rowIndex > 0) {
          targetRow = rowIndex - 1;
        }
        break;
      case 'ArrowDown':
        if (rowIndex < students.length - 1) {
          targetRow = rowIndex + 1;
        }
        break;
      default:
        break;
    }
    const targetField = readingFields[targetFieldIndex];
    const targetInput = document.getElementById(`input-${targetRow}-${targetField}`);
    if (targetInput) {
      targetInput.focus();
    }
  };

  const handleSave = async (student) => {
    try {
      const token = localStorage.getItem('token');
      const eval1 = getValue(student.reading_eval1);
      const eval2 = getValue(student.reading_eval2);
      const eval3 = getValue(student.reading_eval3);
      const eval4 = getValue(student.reading_eval4);
      const response = await fetch(`https://asistencia-betterland.onrender.com/fluidez/estudiantes/${student.rut}/evaluaciones/reading`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          yearFluidez: filterYear,
          reading_eval1: eval1,
          reading_eval2: eval2,
          reading_eval3: eval3,
          reading_eval4: eval4
        })
      });
      if (response.ok) {
        setUpdateMessage(`Evaluaciones actualizadas para ${student.firstname} ${student.lastnamepaternal}`);
        fetchStudents();
      } else {
        setUpdateMessage("Error al actualizar evaluaciones.");
      }
    } catch (error) {
      console.error("Error al actualizar:", error);
      setUpdateMessage("Error al conectar con el servidor.");
    }
  };

  const handleSaveAll = async () => {
    try {
      const token = localStorage.getItem('token');
      const promises = students.map(student => {
        const eval1 = getValue(student.reading_eval1);
        const eval2 = getValue(student.reading_eval2);
        const eval3 = getValue(student.reading_eval3);
        const eval4 = getValue(student.reading_eval4);
        return fetch(`https://asistencia-betterland.onrender.com/fluidez/estudiantes/${student.rut}/evaluaciones/reading`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            yearFluidez: filterYear,
            reading_eval1: eval1,
            reading_eval2: eval2,
            reading_eval3: eval3,
            reading_eval4: eval4
          })
        });
      });
      const results = await Promise.all(promises);
      const allOk = results.every(res => res.ok);
      if (allOk) {
        setUpdateMessage("Evaluaciones actualizadas para todos los estudiantes.");
        fetchStudents();
      } else {
        setUpdateMessage("Error al actualizar algunas evaluaciones.");
      }
    } catch (error) {
      console.error("Error al actualizar en lote:", error);
      setUpdateMessage("Error al conectar con el servidor para guardar todo.");
    }
  };

  const exportCSV = () => {
    if (students.length === 0) return;
    const headers = ["Nivel", "RUT", "Apellido Paterno", "Apellido Materno", "Nombres", "Fluidez Lectora 1", "Fluidez Lectora 2", "Fluidez Lectora 3", "Fluidez Lectora 4"];
    const rows = students.map(stu => [
      stu.nivel,
      stu.rut,
      stu.lastnamepaternal,
      stu.lastnamematernal,
      stu.firstname,
      stu.reading_eval1,
      stu.reading_eval2,
      stu.reading_eval3,
      stu.reading_eval4
    ]);
    const csvContent = "\uFEFF" + headers.join(";") + "\n" + rows.map(row => row.join(";")).join("\n");
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `fluidez_lectora_${filterYear}_${filterNivel}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Función para importar CSV
  const handleImportCSV = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      // Eliminamos el BOM si existe
      const cleanText = text.replace(/^\uFEFF/, '');
      const lines = cleanText.split("\n").filter(line => line.trim() !== "");
      if (lines.length === 0) return;
      // Obtenemos los encabezados
      const headers = lines[0].split(";").map(h => h.trim());
      // Procesamos las filas restantes
      const data = lines.slice(1).map(line => {
        const values = line.split(";").map(v => v.trim());
        const obj = {};
        headers.forEach((header, i) => {
          obj[header] = values[i];
        });
        return obj;
      });
      // Mapeamos los datos a la estructura de "student"
      const importedStudents = data.map(item => ({
        nivel: item["Nivel"],
        rut: item["RUT"],
        lastnamepaternal: item["Apellido Paterno"],
        lastnamematernal: item["Apellido Materno"],
        firstname: item["Nombres"],
        reading_eval1: item["Fluidez Lectora 1"],
        reading_eval2: item["Fluidez Lectora 2"],
        reading_eval3: item["Fluidez Lectora 3"],
        reading_eval4: item["Fluidez Lectora 4"]
      }));
      setStudents(importedStudents);
    };
    reader.readAsText(file, "UTF-8");
  };

  useEffect(() => {
    fetchStudents();
  }, [filterYear, filterNivel]);

  return (
    <div className="page-content">
      <h2 className="page-title">Fluidez Lectora</h2>

      <div className="card filters-container">
        <div className="filter">
          <label>Año:</label>
          <select value={filterYear} onChange={e => setFilterYear(e.target.value)}>
            <option value="">Seleccione el año</option>
            <option value="2025">2025</option>
          </select>
        </div>
        <div className="filter">
          <label>Nivel:</label>
          <select value={filterNivel} onChange={e => setFilterNivel(e.target.value)}>
            <option value="">Seleccione Nivel</option>
            {levelOptions.map(level => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>
        <button className="create-button" onClick={fetchStudents}>Buscar</button>
      </div>

      {loading ? (
        <p>Cargando estudiantes...</p>
      ) : (
        students.length > 0 && (
          <div className="card">
            <div className="table-header">
              <h3>
                {filterNivel ? `Lista de Estudiantes del ${filterNivel}` : "Lista de Estudiantes"}
              </h3>
              <button className="create-button general-save-button" onClick={handleSaveAll}>
                Guardar Todo
              </button>
            </div>
            {updateMessage && <p className="message">{updateMessage}</p>}
            <table className="fluidez-table">
              <thead>
                <tr>
                  <th>Nivel</th>
                  <th>RUT</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nombres</th>
                  <th>Fluidez Lectora 1</th>
                  <th>Fluidez Lectora 2</th>
                  <th>Fluidez Lectora 3</th>
                  <th>Fluidez Lectora 4</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {students.map((stu, index) => (
                  <tr key={stu.rut}>
                    <td>{stu.nivel}</td>
                    <td>{stu.rut}</td>
                    <td>{stu.lastnamepaternal}</td>
                    <td>{stu.lastnamematernal}</td>
                    <td>{stu.firstname}</td>
                    {readingFields.map((field) => (
                      <td key={field}>
                        <input
                          id={`input-${index}-${field}`}
                          type="number"
                          value={stu[field]}
                          onChange={e => handleInputChange(e, index, field)}
                          onKeyDown={e => handleKeyDown(e, index, field)}
                          onWheel={e => e.preventDefault()}
                          style={{ width: '50px', fontSize: '0.8rem' }}
                        />
                      </td>
                    ))}
                    <td>
                      <button className="create-button" onClick={() => handleSave(stu)}>
                        Guardar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ marginTop: '1rem' }}>
              <button className="create-button export-csv-button" onClick={exportCSV}>
                Exportar CSV
              </button>
              <button
                className="create-button import-csv-button"
                onClick={() => fileInputRef.current && fileInputRef.current.click()}
                style={{ marginLeft: '1rem' }}
              >
                Importar CSV
              </button>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImportCSV}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default FluidezLectora;
