import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import './PageStyles.css'; // Reutiliza tus estilos
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

/**
 * Devuelve la cantidad de días que tiene un mes en un año específico.
 * Maneja años bisiestos correctamente.
 */
function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate(); // month es 1..12
}

// Helper: Calcula el número de semana para un día dado del mes
// Se asume que la semana inicia el lunes
function getWeekNumberForDay(day, year, month) {
  const firstDay = new Date(year, month - 1, 1);
  // Ajuste para considerar lunes como inicio (getDay() 0: domingo, 1: lunes, etc.)
  const offset = (firstDay.getDay() + 6) % 7; 
  return Math.floor((day + offset - 1) / 7) + 1;
}

// Objeto de mapeo para mostrar nombres legibles en la tabla
const courseDisplayMap = {
  "10-4-A": "PKA",
  "10-4-B": "PKB",
  "10-5-A": "KA",
  "10-5-B": "KB",
  "110-1-A": "1A",
  "110-1-B": "1B",
  "110-2-A": "2A",
  "110-2-B": "2B",
  "110-3-A": "3A",
  "110-3-B": "3B",
  "110-4-A": "4A",
  "110-4-B": "4B",
  "110-5-A": "5A",
  "110-5-B": "5B",
  "110-6-A": "6A",
  "110-6-B": "6B",
  "110-7-A": "7A",
  "110-7-B": "7B",
  "110-8-A": "8A",
  "110-8-B": "8B",
  "310-1-A": "IA",
  "310-1-B": "IB",
  "310-2-A": "IIA",
  "310-2-B": "IIB",
  "310-3-A": "IIIA",
  "310-3-B": "IIIB",
  "310-4-A": "IVA",
  "310-4-B": "IVB"
};

const juniorCourses = [
  "10-4-A", "10-4-B", "10-5-A", "10-5-B", 
  "110-1-A", "110-1-B", "110-2-A", "110-2-B", "110-3-A", "110-3-B", "110-4-A", "110-4-B"
];

const seniorCourses = [
  "110-5-A", "110-5-B", "110-6-A", "110-6-B", "110-7-A", "110-7-B", "110-8-A", "110-8-B", 
  "310-1-A", "310-1-B", "310-2-A", "310-2-B", "310-3-A", "310-3-B", "310-4-A", "310-4-B"
];

function isJunior(courseName) {
  return juniorCourses.includes(courseName);
}

function isSenior(courseName) {
  return seniorCourses.includes(courseName);
}

export default function Estadisticas() {
  // Año y mes actuales por defecto
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // de 1 a 12

  // Estados para filtros
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [sede, setSede] = useState('All'); // 'All', 'Junior', 'Senior'

  // Datos diarios: array de objetos { course, dailyData: [{ day, present, absent, percentage }, ...] }
  const [dailyStats, setDailyStats] = useState([]);

  // Control de loading y errores
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Referencia para exportar a PDF
  const exportRef = useRef(null);

  // Estado para ordenamiento de la tabla
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  // Cargar datos al cambiar filtros
  useEffect(() => {
    fetchDailyStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, sede]);

  /**
   * Llama al endpoint /estadisticas/diario
   */
  async function fetchDailyStats() {
    try {
      setLoading(true);
      setError('');
      setDailyStats([]);

      const response = await axios.get('https://asistencia-betterland.onrender.com/estadisticas/diario', {
        params: { year, month, sede },
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });

      setDailyStats(response.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Error al obtener estadísticas diarias');
    } finally {
      setLoading(false);
    }
  }

  // Helper: Calcula el promedio de porcentaje para un curso
  function getCourseAverage(courseObj) {
    let sumPercent = 0;
    let count = 0;
    courseObj.dailyData.forEach(dayData => {
      if (dayData.percentage !== null && dayData.percentage !== undefined) {
        sumPercent += dayData.percentage;
        count++;
      }
    });
    return count > 0 ? sumPercent / count : null;
  }

  // Helper: Obtiene el porcentaje de un día para un curso
  function getDayPercentage(courseObj, day) {
    const dayData = courseObj.dailyData.find(d => d.day === day);
    return dayData ? dayData.percentage : null;
  }

  // Función para cambiar la configuración de ordenamiento al hacer clic en un encabezado
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Ordena los datos de dailyStats según sortConfig
  const sortedDailyStats = useMemo(() => {
    let sortable = [...dailyStats];
    if (sortConfig.key) {
      sortable.sort((a, b) => {
        let aVal, bVal;
        if (sortConfig.key === 'course') {
          aVal = a.course.toLowerCase();
          bVal = b.course.toLowerCase();
        } else if (sortConfig.key === 'prom') {
          aVal = getCourseAverage(a) || 0;
          bVal = getCourseAverage(b) || 0;
        } else {
          // Si la key es un día (numérico en formato string)
          const day = Number(sortConfig.key);
          aVal = getDayPercentage(a, day) || 0;
          bVal = getDayPercentage(b, day) || 0;
        }
        if (aVal < bVal) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aVal > bVal) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    return sortable;
  }, [dailyStats, sortConfig]);

  // Calculamos la cantidad de días del mes seleccionado
  const daysInSelectedMonth = getDaysInMonth(year, month);
  const daysArray = Array.from({ length: daysInSelectedMonth }, (_, i) => i + 1);

  // Calcular totales diarios
  const dailyTotals = daysArray.map(day => {
    let totalPresent = 0, totalAbsent = 0;
    dailyStats.forEach(courseObj => {
      const dayData = courseObj.dailyData.find(d => d.day === day);
      if (dayData) {
        totalPresent += dayData.present;
        totalAbsent += dayData.absent;
      }
    });
    const percentage = (totalPresent + totalAbsent) > 0
      ? (totalPresent / (totalPresent + totalAbsent)) * 100
      : null;
    return { day, totalPresent, totalAbsent, percentage };
  });

  // Calcular total global
  let globalPresent = 0, globalAbsent = 0;
  dailyTotals.forEach(d => {
    globalPresent += d.totalPresent;
    globalAbsent += d.totalAbsent;
  });
  const globalPercentage = (globalPresent + globalAbsent) > 0
    ? (globalPresent / (globalPresent + globalAbsent)) * 100
    : null;

  // ================= Estadísticas Semanales =================
  // Calculamos el número de semanas en el mes basado en la agrupación de días
  const weeksInMonth = Math.max(...daysArray.map(day => getWeekNumberForDay(day, year, month)));

  // Para cada curso, agrupamos los días en semanas y calculamos el porcentaje
  const weeklyStats = dailyStats.map(courseObj => {
    // Inicializamos objeto para cada semana
    const weeklyData = {};
    for (let w = 1; w <= weeksInMonth; w++) {
      weeklyData[w] = { present: 0, absent: 0, percentage: null };
    }
    courseObj.dailyData.forEach(dayData => {
      const weekNum = getWeekNumberForDay(dayData.day, year, month);
      if (dayData.present !== undefined && dayData.absent !== undefined) {
        weeklyData[weekNum].present += dayData.present;
        weeklyData[weekNum].absent += dayData.absent;
      }
    });
    // Calcular porcentaje para cada semana
    for (let w = 1; w <= weeksInMonth; w++) {
      const data = weeklyData[w];
      const total = data.present + data.absent;
      if (total > 0) {
        data.percentage = (data.present / total) * 100;
      }
    }
    return { course: courseObj.course, weeklyData };
  });

  // Calcular totales globales semanales (suma de todas las clases)
  const globalWeeklyTotals = {};
  for (let w = 1; w <= weeksInMonth; w++) {
    globalWeeklyTotals[w] = { present: 0, absent: 0, percentage: null };
  }
  dailyStats.forEach(courseObj => {
    courseObj.dailyData.forEach(dayData => {
      const weekNum = getWeekNumberForDay(dayData.day, year, month);
      if (dayData.present !== undefined && dayData.absent !== undefined) {
        globalWeeklyTotals[weekNum].present += dayData.present;
        globalWeeklyTotals[weekNum].absent += dayData.absent;
      }
    });
  });
  for (let w = 1; w <= weeksInMonth; w++) {
    const data = globalWeeklyTotals[w];
    const total = data.present + data.absent;
    if (total > 0) {
      data.percentage = (data.present / total) * 100;
    }
  }
  // ==========================================================

  /**
   * Exporta el contenido del componente a PDF, ajustándolo a una sola página completa en horizontal.
   */
  function exportPDF() {
    const input = exportRef.current;
    if (!input) return;
    html2canvas(input, { scale: 2, useCORS: true }).then(canvas => {
      const pdf = new jsPDF('l', 'mm', 'a4');
      const margin = 5;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const usableWidth = pageWidth - margin * 2;
      const factor = usableWidth / canvas.width;
      const usableHeight = pageHeight - margin * 2;
      
      let currentY = 0;
      while (currentY < canvas.height) {
        const sectionHeightPx = Math.min(canvas.height - currentY, usableHeight / factor);
        const pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        pageCanvas.height = sectionHeightPx;
        const ctx = pageCanvas.getContext('2d');
        ctx.drawImage(
          canvas,
          0,
          currentY,
          canvas.width,
          sectionHeightPx,
          0,
          0,
          canvas.width,
          sectionHeightPx
        );
        const imgData = pageCanvas.toDataURL('image/png');
        if (currentY !== 0) {
          pdf.addPage();
        }
        const pageImgHeight = sectionHeightPx * factor;
        pdf.addImage(imgData, 'PNG', margin, margin, usableWidth, pageImgHeight);
        currentY += sectionHeightPx;
      }
      pdf.save(`estadisticas_diarias_${year}_${month}_${sede}.pdf`);
    });
  }

  /**
   * Exporta las tablas de estadísticas diarias y asistencia semanal a un archivo Excel.
   */
  async function exportExcel() {
    const workbook = new ExcelJS.Workbook();
    
    // HOJA 1: Estadísticas Diarias
    const dailySheet = workbook.addWorksheet('Estadísticas Diarias');

    // Encabezado de la tabla diaria: Curso, 1, 2, ..., n, Prom
    const headerRow = ['Curso', ...daysArray.map(day => day.toString()), 'Prom'];
    const header = dailySheet.addRow(headerRow);
    header.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    // Agregar los datos diarios por curso
    sortedDailyStats.forEach(courseObj => {
      const rowData = [];
      rowData.push(courseDisplayMap[courseObj.course] || courseObj.course);
      daysArray.forEach(day => {
        const dayData = courseObj.dailyData.find(d => d.day === day);
        rowData.push(dayData && dayData.percentage !== null ? `${dayData.percentage.toFixed(1)}%` : '-');
      });
      const avg = getCourseAverage(courseObj);
      rowData.push(avg !== null ? `${avg.toFixed(1)}%` : '-');
      dailySheet.addRow(rowData);
    });

    // Fila de totales diarios
    const totalRow = [];
    totalRow.push('Total');
    daysArray.forEach(day => {
      const dt = dailyTotals.find(d => d.day === day);
      totalRow.push(
        dt && dt.percentage !== null
          ? `${dt.percentage.toFixed(1)}%` + 
            (dt.totalPresent !== 0 || dt.totalAbsent !== 0 ? `\nP: ${dt.totalPresent} A: ${dt.totalAbsent}` : '')
          : '-'
      );
    });
    totalRow.push(
      globalPercentage !== null
        ? `${globalPercentage.toFixed(1)}%` + 
          ((globalPresent !== 0 || globalAbsent !== 0) ? `\nP: ${globalPresent} A: ${globalAbsent}` : '')
        : '-'
    );
    const footer = dailySheet.addRow(totalRow);
    footer.eachCell((cell) => {
      cell.font = { bold: true, size: 8 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // HOJA 2: Asistencia Semanal
    const weeklySheet = workbook.addWorksheet('Asistencia Semanal');
    // Encabezado semanal: Curso, Semana 1, Semana 2, ...
    const weeksHeader = ['Curso'];
    for (let w = 1; w <= weeksInMonth; w++) {
      weeksHeader.push(`Semana ${w}`);
    }
    const weeklyHeaderRow = weeklySheet.addRow(weeksHeader);
    weeklyHeaderRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0070C0' }
      };
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    // Agregar las filas de datos semanales por curso
    weeklyStats.forEach(courseObj => {
      const rowData = [];
      rowData.push(courseDisplayMap[courseObj.course] || courseObj.course);
      for (let w = 1; w <= weeksInMonth; w++) {
        const weekData = courseObj.weeklyData[w];
        rowData.push(weekData && weekData.percentage !== null ? `${weekData.percentage.toFixed(1)}%` : '-');
      }
      weeklySheet.addRow(rowData);
    });

    // Fila de totales semanales (globales)
    const weeklyTotalRow = [];
    weeklyTotalRow.push('Total');
    for (let w = 1; w <= weeksInMonth; w++) {
      const data = globalWeeklyTotals[w];
      weeklyTotalRow.push(data && data.percentage !== null ? `${data.percentage.toFixed(1)}%` : '-');
    }
    const weeklyFooter = weeklySheet.addRow(weeklyTotalRow);
    weeklyFooter.eachCell((cell) => {
      cell.font = { bold: true, size: 8 };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Generar y descargar el archivo Excel
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `estadisticas_${year}_${month}_${sede}.xlsx`);
  }

  // Estilo inline para el contenedor de carga (fondo semitransparente con blur)
  const spinnerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  };

  return (
    <div className="matriculas-container" ref={exportRef}>
      <h1>Estadísticas Diarias de Asistencia</h1>

      {/* Filtros */}
      <div className="filters">
        <div className="filter">
          <label>Año:</label>
          <select value={year} onChange={(e) => setYear(Number(e.target.value))}>
            <option value={2025}>2025</option>
            <option value={2024}>2024</option>
            <option value={2023}>2023</option>
            <option value={2022}>2022</option>
          </select>
        </div>
        <div className="filter">
          <label>Mes:</label>
          <select value={month} onChange={(e) => setMonth(Number(e.target.value))}>
            <option value={1}>Enero</option>
            <option value={2}>Febrero</option>
            <option value={3}>Marzo</option>
            <option value={4}>Abril</option>
            <option value={5}>Mayo</option>
            <option value={6}>Junio</option>
            <option value={7}>Julio</option>
            <option value={8}>Agosto</option>
            <option value={9}>Septiembre</option>
            <option value={10}>Octubre</option>
            <option value={11}>Noviembre</option>
            <option value={12}>Diciembre</option>
          </select>
        </div>
        <div className="filter">
          <label>Sede:</label>
          <select value={sede} onChange={(e) => setSede(e.target.value)}>
            <option value="All">Todas</option>
            <option value="Junior">Sede Junior</option>
            <option value="Senior">Sede Senior</option>
          </select>
        </div>
      </div>

      {loading && <p>Cargando estadísticas diarias...</p>}
      {error && <p className="error">{error}</p>}

      {/* Tabla de asistencia diaria con ordenamiento en los encabezados */}
      <div className="students-list" style={{ marginTop: '20px' }}>
        <h2>Asistencia Diaria por Curso</h2>
        <table className="students-table">
          <thead>
            <tr>
              <th onClick={() => requestSort('course')}>Curso</th>
              {daysArray.map(day => (
                <th key={day} onClick={() => requestSort(day.toString())}>{day}</th>
              ))}
              <th onClick={() => requestSort('prom')}>Prom</th>
            </tr>
          </thead>
          <tbody>
            {sortedDailyStats.map(courseObj => {
              let sumPercent = 0;
              let countPercent = 0;
              return (
                <tr key={courseObj.course}>
                  <td>{courseDisplayMap[courseObj.course] || courseObj.course}</td>
                  {daysArray.map(day => {
                    const dayData = courseObj.dailyData.find(d => d.day === day);
                    if (!dayData) return <td key={day}>-</td>;
                    const { present, absent, percentage } = dayData;
                    if (percentage !== null) {
                      sumPercent += percentage;
                      countPercent++;
                    }
                    return (
                      <td
                        key={day}
                        title={`Presentes: ${present}\nAusentes: ${absent}`}
                        style={{ cursor: 'pointer' }}
                      >
                        {percentage !== null ? `${percentage.toFixed(1)}%` : '-'}
                      </td>
                    );
                  })}
                  <td style={{ fontWeight: 'bold' }}>
                    {countPercent > 0 ? `${(sumPercent / countPercent).toFixed(1)}%` : '-'}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Total</td>
              {daysArray.map(day => {
                const dt = dailyTotals.find(d => d.day === day);
                return (
                  <td
                    key={day}
                    style={{ fontWeight: 'bold', fontSize: '0.8em', cursor: 'pointer' }}
                    title={dt ? `Presentes: ${dt.totalPresent}\nAusentes: ${dt.totalAbsent}` : ''}
                  >
                    {dt && dt.percentage !== null ? `${dt.percentage.toFixed(1)}%` : '-'}
                    <br/>
                    {dt && (dt.totalPresent !== 0 || dt.totalAbsent !== 0)
                      ? `P: ${dt.totalPresent} A: ${dt.totalAbsent}`
                      : ''}
                  </td>
                );
              })}
              <td
                style={{ fontWeight: 'bold', fontSize: '0.8em', cursor: 'pointer' }}
                title={`Presentes: ${globalPresent}\nAusentes: ${globalAbsent}`}
              >
                {globalPercentage !== null ? `${globalPercentage.toFixed(1)}%` : '-'}
                <br/>
                {(globalPresent !== 0 || globalAbsent !== 0)
                  ? `P: ${globalPresent} A: ${globalAbsent}`
                  : ''}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Tabla de asistencia semanal por curso */}
      <div className="students-list" style={{ marginTop: '30px' }}>
        <h2>Asistencia Semanal por Curso</h2>
        <table className="students-table">
          <thead>
            <tr>
              <th>Curso</th>
              {Array.from({ length: weeksInMonth }, (_, i) => (
                <th key={i}>Semana {i + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeklyStats.map(courseObj => (
              <tr key={courseObj.course}>
                <td>{courseDisplayMap[courseObj.course] || courseObj.course}</td>
                {Array.from({ length: weeksInMonth }, (_, i) => {
                  const weekData = courseObj.weeklyData[i + 1];
                  return (
                    <td key={i} title={`P: ${weekData.present} / A: ${weekData.absent}`}>
                      {weekData.percentage !== null ? `${weekData.percentage.toFixed(1)}%` : '-'}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ fontWeight: 'bold' }}>Total</td>
              {Array.from({ length: weeksInMonth }, (_, i) => {
                const data = globalWeeklyTotals[i + 1];
                return (
                  <td key={i} style={{ fontWeight: 'bold' }} title={`P: ${data.present} / A: ${data.absent}`}>
                    {data.percentage !== null ? `${data.percentage.toFixed(1)}%` : '-'}
                  </td>
                );
              })}
            </tr>
          </tfoot>
        </table>
      </div>

      {/* Botones para exportar */}
      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <button className="create-button" onClick={exportPDF}>Exportar PDF</button>
        <button className="create-button" onClick={exportExcel} style={{ marginLeft: '20px' }}>
          Exportar Excel
        </button>
      </div>
    </div>
  );
}
