// src/pages/Atrasos.js
import React, { useEffect, useState, useRef } from 'react';
import Papa from 'papaparse';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend
} from 'chart.js';
import './Atrasos.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);

// Componente para acordeón (renombrado para evitar choques con otros acordeones)
// Se agregó el prop "tooltip" para mostrar un mensaje al pasar el mouse.
const AtrasosAccordionItem = ({ title, children, tooltip }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="atrasos-accordion-item">
      <div
        className="atrasos-accordion-header"
        onClick={() => setIsOpen(!isOpen)}
        title={tooltip} // Aquí se agrega el tooltip
      >
        <h2>{title}</h2>
        <span className="atrasos-accordion-icon">{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="atrasos-accordion-content">{children}</div>}
    </div>
  );
};

function getParsedMonth(marcaTemporal) {
  if (!marcaTemporal) return '';
  const parts = marcaTemporal.split(' ');
  if (parts.length === 0) return '';
  const datePart = parts[0]; // "DD/MM/YYYY"
  const dateSplit = datePart.split('/');
  if (dateSplit.length < 2) return '';
  const parsed = parseInt(dateSplit[1], 10);
  return parsed ? parsed.toString() : '';
}

// Inicializa meses de 3 a 12 y suma atrasos
const calcularAtrasosPorMes = (dataSet) => {
  const meses = {};
  for (let m = 3; m <= 12; m++) {
    meses[m.toString()] = 0;
  }
  dataSet.forEach(row => {
    const parsedM = getParsedMonth(row['Marca temporal']);
    if (parsedM && Number(parsedM) >= 3 && Number(parsedM) <= 12) {
      meses[parsedM] = meses[parsedM] + 1;
    }
  });
  return meses;
};

const Atrasos = () => {
  const [data, setData] = useState([]);

  // --------- Estados para la tabla Resumen (filtros y resultados) ---------
  const [filteredData, setFilteredData] = useState([]);
  const [groupedByStudent, setGroupedByStudent] = useState([]);
  const [analysisByMonth, setAnalysisByMonth] = useState({});
  const [analysisByMonthChart, setAnalysisByMonthChart] = useState({});

  // Filtros para la tabla Resumen
  const [filterName, setFilterName] = useState('');
  const [filterCourse, setFilterCourse] = useState('');
  const [filterMonth, setFilterMonth] = useState('Todos');

  // Estado para saber si se han aplicado filtros en la tabla Resumen
  const [firstTableSearched, setFirstTableSearched] = useState(false);

  // --------- Estados para la tabla Detalle (filtros y resultados) ---------
  const [filterNameSecond, setFilterNameSecond] = useState('');
  const [filterDia, setFilterDia] = useState('Todos');
  const [filterMes, setFilterMes] = useState('Todos');

  // Estado para saber si se han aplicado filtros en la tabla Detalle
  const [secondTableSearched, setSecondTableSearched] = useState(false);

  // --------- NUEVO: Estados para el filtro por Mes y Curso (y gráfico) ---------
  const [filterMonthForCourses, setFilterMonthForCourses] = useState('Todos');
  const [filterCourseForMonth, setFilterCourseForMonth] = useState('');
  // Almacenará la agregación de atrasos por curso, según el mes
  const [analysisByCourse, setAnalysisByCourse] = useState({});

  // Refs para exportar a PDF
  const exportRef = useRef();
  const chartRef = useRef();

  // Opciones disponibles (orden específico)
  const courseOptions = [
    'PKA', 'PKB', 'KA', 'KB', '1A', '1B', '2A', '2B', '3A', '3B',
    '4A', '4B', '5A', '5B', '6A', '6B', '7A', '7B', '8A', '8B',
    'IA', 'IB', 'IIA', 'IIB', 'IIIA', 'IIIB', 'IVA', 'IVB'
  ];
  const monthOptions = ['Todos', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const dayOptions = [
    'Todos', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
  ];
  const monthOptionsForSecond = [
    'Todos', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'
  ];

  // Cargar y parsear el CSV al montar el componente
  useEffect(() => {
    const csvUrl =
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ07xWe5oHYply3KvJrGfCl8mH4O40IdBqswJxso-ZCa0jy05xRvit-ISdfHo3UrqnaNUXMoBm0bROv/pub?output=csv';

    fetch(csvUrl)
      .then(response => response.text())
      .then(csvText => {
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            setData(result.data);
          },
          error: (err) => console.error('Error al parsear el CSV:', err)
        });
      })
      .catch(error => console.error('Error al obtener el CSV:', error));
  }, []);

  // --------- Lógica para la tabla Resumen (y su gráfico) ---------
  useEffect(() => {
    if (!firstTableSearched) {
      setFilteredData([]);
      setGroupedByStudent([]);
      setAnalysisByMonth({});
      return;
    }

    const filtered = data.filter(row => {
      const nameMatch = filterName ? row['Nombre']?.toLowerCase().includes(filterName.toLowerCase()) : true;
      const courseMatch = filterCourse ? row['Curso']?.toLowerCase() === filterCourse.toLowerCase() : true;
      const parsedMonth = getParsedMonth(row['Marca temporal']);
      const monthMatch = filterMonth !== 'Todos' ? parsedMonth === filterMonth : true;
      return nameMatch && courseMatch && monthMatch;
    });

    setFilteredData(filtered);
    setGroupedByStudent(agruparPorEstudiante(filtered));
    setAnalysisByMonth(calcularAtrasosPorMes(filtered));
  }, [filterName, filterCourse, filterMonth, data, firstTableSearched]);

  useEffect(() => {
    if (!firstTableSearched) {
      setAnalysisByMonthChart({});
      return;
    }
    // Para el gráfico comparativo (Marzo a Diciembre) se ignora el filterMonth
    const filteredForChart = data.filter(row => {
      const nameMatch = filterName ? row['Nombre']?.toLowerCase().includes(filterName.toLowerCase()) : true;
      const courseMatch = filterCourse ? row['Curso']?.toLowerCase() === filterCourse.toLowerCase() : true;
      return nameMatch && courseMatch;
    });
    setAnalysisByMonthChart(calcularAtrasosPorMes(filteredForChart));
  }, [filterName, filterCourse, data, firstTableSearched]);

  const agruparPorEstudiante = (dataSet) => {
    const map = {};
    dataSet.forEach(row => {
      const rut = row['Rut'] || '';
      const nombre = row['Nombre'] || '';
      const curso = row['Curso'] || '';
      const key = `${rut}||${nombre}||${curso}`;
      if (!map[key]) {
        map[key] = { rut, nombre, curso, total: 0 };
      }
      map[key].total += 1;
    });
    return Object.values(map);
  };

  // --------- Lógica para la tabla Detalle ---------
  const filteredDataByDiaMes = secondTableSearched
    ? data.filter(row => {
        const nameMatch = filterNameSecond
          ? row['Nombre']?.toLowerCase().includes(filterNameSecond.toLowerCase())
          : true;
        const dayMatch = filterDia !== 'Todos' ? row['DIA'] === filterDia : true;
        const mesMatch = filterMes !== 'Todos' ? row['MES'] === filterMes : true;
        return nameMatch && dayMatch && mesMatch;
      })
    : [];

  // --------- NUEVO: Lógica para filtrar por Mes y Curso + gráfico ---------
  // 1. Función para agrupar atrasos por curso en un mes dado
  const getTardiesByCourseMonth = (month) => {
    const aggregator = {};
    data.forEach(row => {
      const rowMonth = getParsedMonth(row['Marca temporal']);
      // Si month === 'Todos', incluimos todos los meses; si no, comparamos
      if (month === 'Todos' || rowMonth === month) {
        const c = row['Curso'] || '';
        aggregator[c] = (aggregator[c] || 0) + 1;
      }
    });
    return aggregator;
  };

  // 2. Cada vez que cambia el mes, recalculamos la agregación por curso
  useEffect(() => {
    if (!data.length) return;
    const aggregator = getTardiesByCourseMonth(filterMonthForCourses);
    setAnalysisByCourse(aggregator);
  }, [data, filterMonthForCourses]);

  // --- Variables para mostrar solo el total por curso ---
  // Se crea un arreglo con la data agregada
  const aggregatedData = Object.entries(analysisByCourse).map(([course, total]) => ({ course, total }));
  // Si se selecciona un curso específico, filtramos la data
  const filteredAggregatedData = filterCourseForMonth
    ? aggregatedData.filter(item => item.course.toLowerCase() === filterCourseForMonth.toLowerCase())
    : aggregatedData;
  // Ordenamos la data según el orden definido en courseOptions
  const sortedAggregatedData = filteredAggregatedData.slice().sort(
    (a, b) => courseOptions.indexOf(a.course) - courseOptions.indexOf(b.course)
  );

  // Data para el gráfico basado en la data ordenada
  const analysisByCourseChartData = {
    labels: sortedAggregatedData.map(item => item.course),
    datasets: [
      {
        label: 'Atrasos',
        data: sortedAggregatedData.map(item => item.total),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }
    ]
  };

  // --------- Exportar a PDF ---------
  const exportPDF = () => {
    const margin = 10;
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const usableWidth = pageWidth - margin * 2;
    
    // Asegurarse de que exportRef.current esté definido
    if (!exportRef.current) {
      console.error("El elemento a exportar no está definido.");
      return;
    }
    
    html2canvas(exportRef.current, { scale: 2, useCORS: true }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const factor = usableWidth / canvas.width;
      const pageImgHeight = canvas.height * factor;
      
      pdf.addImage(imgData, 'PNG', margin, margin, usableWidth, pageImgHeight);
      
      // Exportar el gráfico principal (chartRef)
      if (chartRef.current) {
        const chartBase64 = chartRef.current.toBase64Image();
        pdf.addPage();
        pdf.addImage(chartBase64, 'PNG', margin, margin, usableWidth, 80);
      }

      pdf.save('atrasos.pdf');
    });
  };

  // --------- Render ---------
  return (
    // Se asigna exportRef al contenedor principal para exportar todo el contenido
    <div className="atrasos-container" ref={exportRef}>
      <h1>Análisis de Atrasos - Asistencia Betterland</h1>

      <div className="atrasos-accordion">
        {/* Acordeón para Tabla Resumen */}
        <AtrasosAccordionItem 
          title="Filtros (Tabla Resumen)" 
          tooltip="Filtra la tabla resumen por Nombre, Curso y Mes (Muestra el total de atrasos por estudiante)"
        >
          <div className="filters">
            <div className="filter-item">
              <label htmlFor="filterName">Buscar por Nombre:</label>
              <input
                type="text"
                id="filterName"
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  if (!firstTableSearched) setFirstTableSearched(true);
                }}
                placeholder="Ingrese nombre"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="filterCourse">Filtrar por Curso:</label>
              <select
                id="filterCourse"
                value={filterCourse}
                onChange={(e) => {
                  setFilterCourse(e.target.value);
                  if (!firstTableSearched) setFirstTableSearched(true);
                }}
              >
                <option value="">Todos</option>
                {courseOptions.map(course => (
                  <option key={course} value={course}>{course}</option>
                ))}
              </select>
            </div>
            <div className="filter-item">
              <label htmlFor="filterMonth">Filtrar por Mes (Marca temporal):</label>
              <select
                id="filterMonth"
                value={filterMonth}
                onChange={(e) => {
                  setFilterMonth(e.target.value);
                  if (!firstTableSearched) setFirstTableSearched(true);
                }}
              >
                {monthOptions.map(m => (
                  <option key={m} value={m}>{m}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Tabla Resumen */}
          <div className="section-container">
            <h2>Resumen de Atrasos (Agrupado por Estudiante)</h2>
            { firstTableSearched ? (
              <div className="table-container">
                <table className="atrasos-table">
                  <thead>
                    <tr>
                      <th>Rut</th>
                      <th>Nombre</th>
                      <th>Curso</th>
                      <th>Total Atrasos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedByStudent.length > 0 ? (
                      groupedByStudent.map((item, index) => (
                        <tr key={index}>
                          <td>{item.rut}</td>
                          <td>{item.nombre}</td>
                          <td>{item.curso}</td>
                          <td>{item.total}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">No se encontraron registros</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Seleccione al menos un filtro para ver los datos.</p>
            )}
          </div>

          {/* Gráfico de Atrasos (Marzo a Diciembre) */}
          <div className="section-container">
            <h2>Atrasos Totales por Mes (Comparativo)</h2>
            <div className="chart-container">
              {Object.keys(analysisByMonthChart).length > 0 ? (
                <Bar
                  ref={chartRef}
                  data={{
                    labels: ["3","4","5","6","7","8","9","10","11","12"],
                    datasets: [
                      {
                        label: 'Total de Atrasos',
                        data: ["3","4","5","6","7","8","9","10","11","12"].map(
                          m => analysisByMonthChart[m] || 0
                        ),
                        backgroundColor: 'rgba(0, 123, 255, 0.6)',
                        borderColor: 'rgba(0, 123, 255, 1)',
                        borderWidth: 1
                      }
                    ]
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: { position: 'top' },
                      title: { display: true, text: 'Comparativo de Atrasos (Marzo a Diciembre)' }
                    }
                  }}
                />
              ) : (
                <p>No hay datos para mostrar en el gráfico.</p>
              )}
            </div>
          </div>
        </AtrasosAccordionItem>

        {/* Acordeón para Tabla Detalle */}
        <AtrasosAccordionItem 
          title="Filtros (Tabla Detalle)"
          tooltip="Filtra la tabla detalle por Nombre, Día y Mes, (muestra las horas de ingreso)"
        >
          <div className="filters">
            <div className="filter-item">
              <label htmlFor="filterNameSecond">Buscar por Nombre:</label>
              <input
                type="text"
                id="filterNameSecond"
                value={filterNameSecond}
                onChange={(e) => {
                  setFilterNameSecond(e.target.value);
                  if (!secondTableSearched) setSecondTableSearched(true);
                }}
                placeholder="Ingrese nombre"
              />
            </div>
            <div className="filter-item">
              <label htmlFor="filterDia">Filtrar por Día:</label>
              <select
                id="filterDia"
                value={filterDia}
                onChange={(e) => {
                  setFilterDia(e.target.value);
                  if (!secondTableSearched) setSecondTableSearched(true);
                }}
              >
                {dayOptions.map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>
            </div>
            <div className="filter-item">
              <label htmlFor="filterMes">Filtrar por Mes (columna MES):</label>
              <select
                id="filterMes"
                value={filterMes}
                onChange={(e) => {
                  setFilterMes(e.target.value);
                  if (!secondTableSearched) setSecondTableSearched(true);
                }}
              >
                {monthOptionsForSecond.map(m => (
                  <option key={m} value={m}>{m}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Tabla Detalle */}
          <div className="section-container">
            <h2>Registros filtrados por Día y Mes</h2>
            { secondTableSearched ? (
              <div className="table-container">
                <table className="atrasos-table">
                  <thead>
                    <tr>
                      <th>Rut</th>
                      <th>Nombre</th>
                      <th>Curso</th>
                      <th>Día</th>
                      <th>Mes</th>
                      <th>Hora de Entrada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDataByDiaMes.length > 0 ? (
                      filteredDataByDiaMes.map((row, index) => (
                        <tr key={index}>
                          <td>{row['Rut']}</td>
                          <td>{row['Nombre']}</td>
                          <td>{row['Curso']}</td>
                          <td>{row['DIA']}</td>
                          <td>{row['MES']}</td>
                          <td>{row['Marca temporal']}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6">No se encontraron registros</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>Seleccione al menos un filtro para ver los datos.</p>
            )}
          </div>
        </AtrasosAccordionItem>

        {/* NUEVO Acordeón: Filtro por Mes y Curso con tabla agregada y gráfico */}
        <AtrasosAccordionItem 
          title="Atrasos por Mes y Curso"
          tooltip="Analiza los atrasos por Mes y Curso, mostrando totales y un gráfico comparativo"
        >
          {/* Filtros */}
          <div className="filters">
            <div className="filter-item">
              <label>Filtrar por Mes:</label>
              <select
                value={filterMonthForCourses}
                onChange={(e) => setFilterMonthForCourses(e.target.value)}
              >
                {monthOptions.map(m => (
                  <option key={m} value={m}>{m}</option>
                ))}
              </select>
            </div>
            <div className="filter-item">
              <label>Filtrar por Curso:</label>
              <select
                value={filterCourseForMonth}
                onChange={(e) => setFilterCourseForMonth(e.target.value)}
              >
                <option value="">Todos</option>
                {courseOptions.map(c => (
                  <option key={c} value={c}>{c}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Tabla que muestra el total de atrasos por curso */}
          <div className="section-container">
            <h2>Tabla de Atrasos (Mes y Curso)</h2>
            <div className="table-container">
              <table className="atrasos-table">
                <thead>
                  <tr>
                    <th>Curso</th>
                    <th>Total Atrasos</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAggregatedData.length > 0 ? (
                    sortedAggregatedData.map((item, i) => (
                      <tr key={i}>
                        <td>{item.course}</td>
                        <td>{item.total}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No se encontraron registros</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* Gráfico de barras basado en la data ordenada */}
          <div className="section-container">
            <h2>Gráfico: Atrasos por Curso</h2>
            <div className="chart-container">
              {sortedAggregatedData.length > 0 ? (
                <Bar
                  data={analysisByCourseChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: { position: 'top' },
                      title: {
                        display: true,
                        text: 'Atrasos por Curso en el Mes Seleccionado'
                      }
                    }
                  }}
                />
              ) : (
                <p>No hay datos para mostrar en el gráfico.</p>
              )}
            </div>
          </div>
        </AtrasosAccordionItem>
      </div>

      {/* Botón Exportar a PDF (fuera de los acordeones) */}
      <div className="section-container">
        <div className="export-btn-container">
          <button className="export-btn" onClick={exportPDF}>
            Exportar a PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default Atrasos;
