import React, { useState, useEffect } from 'react';
import './PageStyles.css';

const GestionUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('viewer');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [csvFile, setCsvFile] = useState(null);

  // Obtener la lista de usuarios
  const fetchUsuarios = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://asistencia-betterland.onrender.com/users', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error(`Error ${response.status}: ${response.statusText}`);
      const data = await response.json();
      setUsuarios(data);
    } catch (error) {
      console.error('Error al obtener la lista de usuarios:', error);
      setUsuarios([]);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  // Crear usuario manual
  const handleCrearUsuario = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://asistencia-betterland.onrender.com/users/create', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password, role }),
      });

      if (response.ok) {
        setMessage('Usuario creado con éxito');
        setUsername('');
        setPassword('');
        setRole('viewer');
        fetchUsuarios();
      } else {
        setMessage('Error al crear usuario');
      }
    } catch {
      setMessage('Error al conectar con el servidor');
    }
  };

  // Eliminar usuario
  const handleEliminarUsuario = async (nombreUsuario) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://asistencia-betterland.onrender.com/users/delete/${nombreUsuario}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setMessage('Usuario eliminado con éxito');
        setUsuarios((prev) => prev.filter((u) => u.username !== nombreUsuario));
      } else {
        const data = await response.json();
        setMessage(data.message || 'Error al eliminar usuario');
      }
    } catch {
      setMessage('Error al conectar con el servidor');
    }
  };

  // Resetear contraseña
  const handleResetPassword = async (nombreUsuario) => {
    const newPassword = prompt(`Ingrese la nueva contraseña para ${nombreUsuario}:`);
    if (!newPassword || newPassword.length < 6) {
      alert('La contraseña debe tener al menos 6 caracteres.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://asistencia-betterland.onrender.com/users/reset-password/${nombreUsuario}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });

      const data = await response.json();
      setMessage(data.message || 'Error al resetear la contraseña');

      if (response.ok) {
        alert(`Contraseña de ${nombreUsuario} actualizada con éxito`);
      }
    } catch {
      setMessage('Error al conectar con el servidor');
    }
  };

  // Seleccionar archivo CSV (sin procesarlo automáticamente)
  const handleFileSelect = (event) => {
    setCsvFile(event.target.files[0]);
  };

  // Procesar y subir CSV
  const handleUploadCsv = () => {
    if (!csvFile) {
      alert('No se ha seleccionado un archivo CSV');
      return;
    }
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csvData = target.result;
      // Remover BOM si existe
      const dataSinBOM = csvData.replace(/^\uFEFF/, '');
      // Separar líneas y columnas usando ';'
      const rows = dataSinBOM.split('\n').map((row) => row.split(';'));
      // Se asume que la primera fila es el header
      const formattedData = rows.slice(1).map(([username, password, role]) => ({
        username: username?.trim(),
        password: password?.trim(),
        role: role?.trim(),
      })).filter((user) => user.username && user.password && user.role);

      if (formattedData.length > 0) {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch('https://asistencia-betterland.onrender.com/users/bulk-create', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formattedData),
          });

          if (response.ok) {
            setMessage('Usuarios agregados con éxito');
            fetchUsuarios();
            setCsvFile(null);
          } else {
            setMessage('Error al cargar usuarios desde CSV');
          }
        } catch {
          setMessage('Error al conectar con el servidor');
        }
      }
    };

    reader.readAsText(csvFile);
  };

  // Descargar CSV vacío con separador ";", agregando BOM para compatibilidad con Excel
  const handleDownloadCsv = () => {
    const csvContent = 'username;password;role\n';
    const blob = new Blob(['\uFEFF' + csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Ordenar tabla
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedUsuarios = [...usuarios].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setUsuarios(sortedUsuarios);
  };

  return (
    <div className="cargausuarios-page-content">
      <h2 className="cargausuarios-page-title">Gestión de Usuarios</h2>

      {/* Fila con dos tarjetas: Carga Manual y Carga CSV */}
      <div className="cargausuarios-row-of-cards">
        <div className="cargausuarios-card">
          <h3>Carga Manual</h3>
          <form onSubmit={handleCrearUsuario} className="cargausuarios-user-form">
            <input
              type="text"
              placeholder="Nombre de usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <div className="cargausuarios-password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="cargausuarios-show-password-button"
              >
                {showPassword ? 'Ocultar' : 'Mostrar'}
              </button>
            </div>
            <select value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="admin">Admin</option>
              <option value="data_manager">Data Manager</option>
              <option value="viewer">Viewer</option>
              <option value="convivencia">Convivencia</option>
              <option value="profesor_jefe">Profesor Jefe</option>
              <option value="fluidez">Fluidez</option>
            </select>
            <button type="submit" className="cargausuarios-create-button">
              Crear Usuario
            </button>
          </form>
        </div>

        <div className="cargausuarios-card">
          <h3>Carga CSV</h3>
          <button
            type="button"
            onClick={handleDownloadCsv}
            className="cargausuarios-download-csv-button"
          >
            Descargar CSV Vacío
          </button>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
            className="cargausuarios-csv-upload"
          />
          <button
            type="button"
            onClick={handleUploadCsv}
            className="cargausuarios-upload-csv-button"
          >
            Subir CSV
          </button>
        </div>
      </div>

      {message && <p className="cargausuarios-message">{message}</p>}

      {/* Lista de Usuarios en contenedor separado */}
      <div className="cargausuarios-card">
        <h3>Lista de Usuarios</h3>
        <table className="cargausuarios-user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th onClick={() => handleSort('username')}>Usuario</th>
              <th onClick={() => handleSort('role')}>Rol</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => (
              <tr key={usuario.id}>
                <td>{usuario.id}</td>
                <td>{usuario.username}</td>
                <td>{usuario.role}</td>
                <td>
                  <button
                    onClick={() => handleResetPassword(usuario.username)}
                    className="cargausuarios-reset-button"
                  >
                    Resetear Contraseña
                  </button>
                  <button
                    onClick={() => handleEliminarUsuario(usuario.username)}
                    className="cargausuarios-delete-button"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GestionUsuarios;
